@import './variables.scss';

.p-dropdown {
    width: 250px;

    .p-dropdown-panel {
        width: 100%;
    }
}

.p-datepicker .p-highlight,
.p-dropdown-panel .p-highlight {
    color: $aumnics-white  !important;
    background-color: $selection-color  !important;
}

// .p-autocomplete-items,
// .p-multiselect-items {
//     padding: 0px !important;
// }

// .p-autocomplete-item,
// .p-multiselect-item,
// .p-autocomplete-empty-message,
// .p-multiselect-empty-message {
//     height: auto !important;
// }

// .p-multiselect-item {
//     min-height: 40px !important;
// }

*[class*="-items"] {
    padding: 0px !important;
}

*[class*="-item"],
*[class*="-empty-message"] {
    height: auto !important;
}

.p-multiselect.wrap {
    height: auto !important;
    min-height: 48px;

    .p-multiselect-label {
        white-space: pre-wrap;
    }

    .p-multiselect-token {
        margin-bottom: 5px;
        color: $aumnics-white;
        background-color: $selection-color;
    }
}

.new-form-control .p-multiselect-label {
    padding: 0px !important;
}

.p-tooltip {
    min-width: fit-content;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    height: 35px !important;
    padding: 0 15px !important;
    @include d-center;
}

.p-scroller,
.p-dropdown-items-wrapper {
    margin-bottom: 10px;
}

.fromNewTaskManager {
    .new-form-control {
        border: 0 !important;
    }

    .shortTimeInput.border-bottom {
        border-bottom: 1px solid $border-color !important;
    }

    .new-form-control:focus {
        box-shadow: 0 0 0 0 !important;
        border-color: unset !important;
        border: 0 !important;
    }

    .latest-search {
        border-radius: 0px !important;
    }

    .p-dialog .p-dialog-header {
        color: #818193 !important;
        font-weight: 500;
    }

    .data-actions-menu {
        z-index: 30000;
    }
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 10px;
}

.users-list-parent {
    max-height: 260px;
    overflow-y: scroll;
}
.p-overlaypanel {
    background: #fff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.p-overlaypanel {
    box-shadow: 0 1px 12px 4px #0003 !important;
}
.overflow-hidden.p-overlaypanel {
    overflow: hidden !important;
}

.fromNewTaskManager.p-overlaypanel {
    border-radius: .625rem !important;
}


.fromNewTaskManager.p-overlaypanel-flipped:after,
.p-overlaypanel-flipped:before {
    bottom: 0% !important;
    top: 0% !important;
}

.fromNewTaskManager.p-overlaypanel:after,
.p-overlaypanel:before {
    bottom: 0% !important;
    border-bottom-color: transparent;
    display: none;
}

.fromNewTaskManagerLeftArrowHide.p-overlaypanel:after,
.p-overlaypanel:before {
    left: 7% !important;
}

.p-overlaypanel::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    /* Adjust as necessary */
}
.p-panel-cards-styles {
    padding: 4px 8px !important;
    cursor: pointer;
  
    &:hover {
        background-color: #f0f1f3;
        border-radius: 6px;
    }
}

.new-label-form {
    font-size: 14px;
    color: #4f5762;
    font-weight: 500;
    margin-bottom: 14px !important;
}

.new-form-control {
    display: block;
    width: 100%;
    height: 38px !important;
    padding: 5px 16px !important;
    font-size: 15px;
    line-height: 1.42857143;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    border: 1px solid #dfe7ef !important;
    border-radius: 5px !important;
    color: $text-head-color !important;
    font-weight: $font-weight-400 !important;
    margin-right: 3px;

    &:focus {
        box-shadow: none !important;
    }
}

textarea.new-form-control {
    height: 90px;
    padding: 10px 15px !important;
    resize: none !important;
    max-height: 320px !important;
    min-height: 90px;
}

.textarea.new-form-control.editing-task-input {
    height: 60px;
    min-height: 60px !important;
    max-height: 120px !important;
}

.new-form-control:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #b2d1f7 !important;
    border-color: $blue-text-color !important;
}

.new-form-control.is-invalid,
.was-validated .new-form-control:invalid {
    background-image: none !important;
}

.new-form-control:disabled,
.new-form-control[readonly],
select:disabled,
input[type="radio"]:disabled,
input[type="radio"]:disabled~label {
    cursor: no-drop !important;
    //border: unset !important;
}

.dropdown-icon {
    position: absolute;
    right: 12px;
    color: #adb3bd;
    top: 11px;
    font-size: .8rem;
}

.latest-search {
    border-radius: 9px !important;
    height: 38px !important;
    background-size: 17px;
    padding: 10px 15px 10px 42px !important;
}

#onboardingForm .field {
    margin-bottom: 1.7rem !important;
}

#onboardingForm {
    .p-autocomplete-input {
        height: 38px;
        font-size: 14px;
    }
    ::placeholder {
        color: #818ead !important;
    }
}
.p-checkbox-label {
    margin-bottom: 0px;
}

.filter-div {
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}
.remaining-tags {
    border-radius: 50%;
    padding: 2px;
    background-color: #cbc9cf;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
    height: 23px;
    min-width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 7px;
    font-size: .8rem;
}
.timeInputRightSuggestionPanel .p-overlaypanel-content {
    min-width: 150px !important;
}

.timeInputRightSuggestionPanel.p-overlaypanel:after {
    margin-left: 50px !important;
}

.time-tracker__features {
    display: flex;
    padding: .5rem 1rem;
    color:  rgb(101, 111, 125);
}

.fromNewTaskManager.task-priority-panel {
    max-height: 200px;
    overflow-y: overlay;
}

.fromNewTaskManager.task-priority-panel.p-overlaypanel {
    min-width: 180px;
}

.timeInputSuggestionPanel,
.plain-input {
    min-width: 100px;
}

.plain-input {
    max-width: 120px;
}
.time-tracker__features {
    .p-inputtext {
        height: 38px;
        padding: 8px 10px !important;
    }
}

.p-autocomplete {
    position: inherit !important;
}

.p-datepicker table td>span {
    width: 1.5rem;
    height: 1.5rem;
}
.p-calendar {
    margin-top: 0px;
}
.toasterStyles {
    word-break: break-word;
    right: 15px !important;
    bottom: 15px !important;
    width: 400px;
    margin: 0px !important;
    left: 20px !important;

    .close {
        right: 0 !important;
        top: 2px !important;
        outline: none;
        opacity: 1 !important;

        &:focus {
            outline: none;
        }

        &:hover {
            -moz-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
        }
    }

    button:hover {
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
    }
}

.toasterStyles.alert-success {
    color: #2a2e34;
    background-color: $aumnics-white;
    ;
    border-color: $aumnics-white;
    ;
    box-shadow: 0 0.0625rem 0.5875rem rgba(0, 0, 0, 0.106), 0 0.625rem 0.325rem -0.625rem rgba(0, 0, 0, 0.106);
    border-left: 3px solid blue;

    .close {
        &:hover {
            color: #2a2e34;
        }
    }
}
.style-close {
    position: absolute;
    right: 15px;
    top: 16px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
    }
}

.toasterStyles.alert-danger {
    color: $aumnics-white;
    ;
    background-color: #eb95b8;
    border-color: #f5c6cb;
    border-left: 3px solid #f34e92;
    box-shadow: 0 0.0625rem 0.5875rem rgba(0, 0, 0, 0.106), 0 0.625rem 0.325rem -0.625rem rgba(0, 0, 0, 0.106);

    .close {
        &:hover {
            color: $aumnics-white;
            ;
        }
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes slideInBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOutTop {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.slideInRight {
    animation: slideInBottom 0.5s forwards;
}

.slideOutLeft {
    animation: slideOutTop 0.5s backwards;
}

@media screen and (max-width: 470px) and (min-width: 320px) {
    .toasterStyles {
        width: 92%;
    }

    .emptyIllustrateImg {
        width: 90px;
    }
}