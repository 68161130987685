$new-primary-button-color: #5F54EE;
$new-primary-button-hover-color: #5146f4;

$new-icon-hover-color: #f0f1f3;
$tags-background-color: #f3f7fa;
$aumnics-white: #ffffff;
$aumnics-black: #000000;
$aumnics-red: #f00;
$primary-color: $new-primary-button-color;
$primary-color-2: $new-primary-button-color;
$button-start-bg: #9333ea;
$button-end-bg: #2563eb;
$cfa-btn-color: #facc15;
$cfa-btn-hover-color: #e8c810;
$primary-color-3: #C7BBFE;
$banners-background-color:#fbf7fe; 
$buttons-hover-color: #002ce1;
$secondary-color: #0CC8E8;
$delete-color: #FF1950B2;
$bg-color: #F9F9F9;
$selection-color: $primary-color;
$secondary-card-bg-color: #dedeff;
$secondary-bg-color : #F6F5FF;
$secondary-bg-color-on-hover: #f1efff;
$cfa-start-bg : #000097;
$cfa-end-bg : #6363C4;

$form-control-bg-color : #f2f3f5;
$placeholder-color: #A4ADC3;
$dark-gray: #BFBFBF;
$box-shadow-color: #afc0e24d;
$pitch-cards-background: #e8e8e8;

$section-bg-primary: #F6F5FF;
$section-bg-secondary: #f3fdff;
$section-bg-teritiary: #fffbf4;
$home-banner-bg : #eceeff;
$banner-bg-color : $secondary-bg-color;

$card-yellow: #fbcc5b;
$card-green: #84ca7c;
$card-hover-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;

$border-color: #dfe7ef;//#d7e3ee;
$prime-tag-color: #F8C742;
$tab-border: #dee2e6;
$box-shadow: #b2d1f7;
$hover-border-color: #007fe1;
$tags-border: #dfe7ef;
$banner-background-color: #ECEBFE;
$sober-background-color: #F2F2F2;
$bg-color-light-blue: rgb(229, 255, 235);
$location-bg: #F6F6F6;
$delete-brown-bg: #bc4841;
$feed-notification: #fafbfc;
$subscription-error-bg : #ead6fd;
$gray-2-description-color: #87909e;
$new-dropdown-border-color : #D6D9DE;
$formcontrol-focus-shadow-color: #b2d1f7;
$text-color: #4b5563;
$sub-heading-color: #818EAD;
$new-icon-default-color: #4f5762;
$primary-blue-color: #2a2e34;
$secondary-dark-color: #656f7d;
$black: #000;
$text-head-color: #334155;
$titles-color: #111827;
$nav-links-color: $text-color;
$text-Subhead-color: #96a1ba;
$filtername-card-bg: #f2f2f2a6;
$logo-bg-color: #f2f2f2;
$gray-bg: #e9eef1;
$icon-gray-color: #adb3bd;
$new-task-manager-blue: #6962c7;
$sub-heading-color-cu:#34343499;
$add-bug-bg:#f2f1fe;
$column-hover-color: rgb(240, 241, 243);
$delete-brown-bg: #bc4841;
$feed-notification: #fafbfc;
$subscription-error-bg : #ead6fd;
$gray-2-description-color: #87909e;
$new-dropdown-border-color : #D6D9DE;
$formcontrol-focus-shadow-color: #b2d1f7;
$new-input-textform-color: #666666;
$editor-icons-hover:  $new-icon-hover-color; // #e9f2f9;emailErrorColor
$label-responsive-color: #788092;// #7e8bab;
$icon-hover-color: #f4f4f4;
$icon-bg-color: #E7E7FF;
$icon-bg-color-hover: #d8d8fe;
$chat-menu-div: #f7f8f9;
$title-color: $text-color;
$global-project-color: $primary-blue-color;
$emailErrorColor : #b94c52;
$emailErrorBg : #fceff0;
$primary-chat-hover-color: #e8eaed;
$blue-text-color: #4466ff;


$border-color: #e8e8e8;
$tabs-background:#f2f2f2;
$tabs-hover-color: #e6e6e6;
$footer-links-color: #000000AB;
$card-label-color:#767676;
$header-box-shadow-color: #cfdee61a;
$nav-link-active-color: #4B47F1;
$orange-btn-background : #e59217;

$contentBlockRadius: 11px;
$amy-bot-border-color: #4f4f4f;
$editor-icons-hover: #e9f2f9;
$selected-chat: #e5e4fc;
$skill-bg-card: #EEEDFF;
$pre-background: #efefef80;
$pre-border: #cccccc7d;
$chat-menu-hoverBgColor: rgb(232, 234, 237);

$recomendedPriceTagBackground : #fdbc68;
$active-selected-color: #e5e4fc;
$secondary-save-btn-color: #e5e4fc;
$primary-blue-color-2 : $primary-color-2;
$tag-primary-text : #22AB47;
$cardsHoverBg : #f7f8f9;
$error-color: #ff0000;
$headingSize: 35px;
$subHeadingSize: 18px;
$contentBlockRadius: 11px;
$letter-spacing: 0.7px;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$field-radius: 4px;
$new-border-color: #dfe7ef;
$green-text: #1ec377;
$overCapacityBg : #e1646a;
$scroll-thumb: #adb3bd;
$formcontrol-focus-shadow-color: #b2d1f7;
$blue-light-bg-files:#F5F5FE;
$header-cards-bg: #1A1550;




.title-color {
    color: $title-color !important;
  }
  
  .sub-heading-color {
    color: $sub-heading-color !important;
  }
  
  .global-project-color {
    color: $global-project-color !important;
  }
  
  .primary-blue-color {
    color: $primary-blue-color !important;
  }

  .text-green{
    color: $green-text !important;
  }

  .text-red {
    color: $aumnics-red !important;
  }

  // make text white dominant color
  .title-color.text-white, 
  .sub-heading-color.text-white, 
  .global-project-color.text-white, 
  .primary-blue-color.text-white, 
  .text-green.text-white, 
  .text-red.text-white {
    color: $aumnics-white !important;
  }
  