/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import 'maplibre-gl/dist/maplibre-gl.css';
@tailwind base;
@tailwind components;
@tailwind utilities;





@import './variables';
@import './mixins';
@import './buttons';
@import './forms';
@import './primeng';
@import './time-sheet.styles';
@import './task-manager.styles';
@import './nav-styles.scss';
@import './icons';


/*--------------------*/
// neuo font family
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900; /* Variable font weight range */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../../assets/fonts/geist-fonts/Geist-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }  
  

/*-----------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 10px;
    letter-spacing: 0.56px;
    display: block;
}

/*-------Css resets ------*/

html,
body {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
box-sizing: border-box;
}

html {
-ms-text-size-adjust: 100%;
-webkit-text-size-adjust: 100%;
}

body {
font-family: "Geist", Roboto, sans-serif !important;
font-weight: normal;
background: $aumnics-white; color:$text-head-color; font-size: 16px;

}

.mat-typography, .p-component{
    font-family: "Geist", Roboto, sans-serif !important;
    font-size: 16px;
}

ol,
ul,
p {
list-style: none;
margin: 0;
padding: 0;
font-family: "Geist", Roboto, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,figure {
margin: 0;
font-weight: 700 !important;
font-family: "Geist", Roboto, sans-serif !important;
color: $titles-color;
}
a:hover{ text-decoration: none;}

a,a:hover,a::after, a:hover::after{
-webkit-transition:all 0.1s ease-in-out;-moz-transition:all 0.1s ease-in-out;-o-transition: all 0.1s ease-in-out;transition: all 0.1s ease-in-out; text-decoration: none;
}
.form-control:focus {
    box-shadow: none; background-color: transparent;
}

/*----------------------------*/


/**--------------------*/



* {
    letter-spacing: 0.4px !important;
    padding-inline-start: 0px;
  }

/*----- Global styles -------*/
.container {
    max-width: 1600px; margin: 0 auto; display: block; padding: 0;
}
.paragraph-global-text{
    font-size: 16px !important; line-height: 25px !important; display: inline-block; position: relative; margin: 0;color: $text-head-color;
}

.banner-heading{
    font-size: 50px !important;
    line-height:55px !important; margin-bottom: 15px;
    background: linear-gradient(to right, #28287D, #4949E3);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.connect-banner .banner-heading{
    font-size: 54px !important;
    line-height: 60px;
}

.amy-banner-heading{
    font-size: 3.75rem !important;
    line-height:1.2 !important; margin-bottom: 15px;
    // background: linear-gradient(to right, #28287D, #4949E3);
    // background-clip: text;
  //  -webkit-text-fill-color: transparent;
}

.sec-heading {
    font-size: 40px !important;
    line-height:1 !important; margin-bottom: 15px;
}

.heading-one{
    font-size: 45px !important;
    line-height:1 !important; margin-bottom: 15px;
    background: linear-gradient(to right, #28287D, #4949E3);
    background-clip: text;
   -webkit-text-fill-color: transparent;
}

.amy-heading-one{
    font-size: 38px !important;
    line-height:48px !important; margin-bottom: 15px;
    background: linear-gradient(to right, #28287D, #4949E3);
    background-clip: text;
  -webkit-text-fill-color: transparent;
}

.amy-sec-heading-one{
    font-size: 55px !important;
    line-height:60px !important; margin-bottom: 15px;
}

.heading-two{
    font-size: 30px !important;
    line-height:1 !important;
    font-weight: 700 !important;
}
.heading-three{
    font-size: 20px !important;line-height:30px !important; margin-bottom: 10px; font-weight: 700 !important;
}

.amy-heading-three{
    font-size: 25px !important;line-height:30px !important; margin-bottom: 10px; font-weight: 700 !important;
}

.heading-four {
    font-size: 16px !important;line-height:28px !important; margin-bottom: 10px; font-weight: 600 !important;
}

.paragraph-global-subtest{
    font-size: 16px !important; line-height: 25px !important; display: inline-block; color: $aumnics-white; position: relative;
    display: inline-block;
}

.gradientText {
  background: linear-gradient(to right, #28287D, #4949E3);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.secondary-gradient-text {
  background: linear-gradient(to right, #41D3F3, white);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-sec .paragraph-global-text { font-size: 20px !important;}
.banner-sec .paragraph-global-text.f-12 { font-size: 12px !important;}
.paragraph-global-text.sub-head-paragraph{ font-size: 16px !important;}
.paragraph-global-text.title-description { font-size: 20px !important}
.sub-heading-one{
    font-size: 18px !important; color: $primary-color;
    line-height:20px !important; margin-bottom: 15px;
}
.sub-heading-two{
    font-size: 18px !important;        
}
.mat-drawer-container{
    color: $text-head-color;
}



.btn.focus, .btn:focus{
    outline: none;box-shadow: none;
}

.text-wrap,
.active-white {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-text-wrap {
    white-space: nowrap !important;
}


/** icons classes */
.dots {
    background-image: url("./../../assets/images/layout-icons/more.svg");
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
    min-width: 35px !important;
    height: 35px;
    border-radius: 50%;
  
    &::before {
      display: none !important;
    }
  }


.p.que{
    color: $text-head-color;
    font-size: 18px;
    font-weight: 500;
}

.h-65{
    height: 65vh !important;
}

.bold-text {
    font-weight: bold !important;
}
.text-white{
    color: $aumnics-white !important;
}

.cfa-color {
  color: $cfa-btn-color !important;
}

.icon-primary {
  color: $cfa-btn-color !important;
}

.icon-primary-color {
  color: #5F54EE !important; 
}

.text-whitish-gray {
  color: $aumnics-white !important;
  opacity: .7;
}

.amy-heading-one.text-white, 
.banner-heading.text-white, 
.heading-one.text-white{
  -webkit-text-fill-color: $aumnics-white !important;
}

/*----- Social icons styles -------*/
.social-icons {
	display: block;

	ul {
		display: flex;
		align-items: center;

		li {
			margin: 0 10px;

			a {
				.fa-brands {
					color: $text-head-color;
					cursor: pointer;
					font-size: 18px;
					-webkit-transition: 0.6s ease-out;
					-moz-transition: 0.6s ease-out;
					transition: 0.6s ease-out;
                    &:hover{
                        //color:#8e0082;
                        color: #604bff;
                    }
				}                
			}
		}
	}

	//a {
		// .fa-brands {
		// 	&:hover {
		// 		-webkit-transform: rotateX(-360deg);
		// 		-moz-transform: rotateX(-360deg);
		// 		-ms-transform: rotate(-360deg);
		// 		transform: rotateX(-360deg);
		// 		-webkit-transition: 0.6s ease-out;
		// 		-moz-transition: 0.6s ease-out;
		// 		transition: 0.6s ease-out;
		// 	}
		// }
	///}
}

/**  app layout css*/
.mat-drawer-container {
    height: 100vh;
    width: 100vw;
  }
  
  .scrollableContent{
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
/** banners with sticky header Amy , cms , clickit */
.sticky-header-banner{
    margin-top: -76px;
}


/*-------------------*/
.about-us-banner { background: $banners-background-color; padding: 50px 0;}
.about-us-cont { display: flex; align-items: center; justify-content: space-between;}
.about-us-lft{ padding: 0;}
.about-us-lft h2.heading-one { margin-bottom: 20px;}
.about-us-lft span { color: $primary-color;}
.about-us-lft small { position: absolute; bottom:-7px; left: 0; line-height: 0;}
.about-us-rgt{padding: 0;}

/*---------------------------*/
.user-access{
    background: #FAFAFA; padding: 50px 0 30px; display: grid; place-items:center;
    .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $aumnics-white;
        border-radius: 4px;
        padding: 5px 10px;
        width: 100%;
        border: #EBEBEB 1px solid;box-shadow: 0px 3px 2px #C1C1C129; margin-bottom: 40px;
    
        span.fa {
            color: #9f9f9f;
            width: 20px;
            font-size: 15px;
        }
    
        .form-control {
            background: transparent !important;
            color: #747474 !important;
            border-bottom: none;
        }
    }
    .bread-crums{
        display: flex; align-items: center; justify-content: space-between;
        ul{
            display: flex; align-items: center;
            li{
                a{
                    font-size: 18px; line-height: 18px;cursor: pointer; color:#222;
                    .mat-icon { height: auto; vertical-align: middle;}
                }
            }
        }.icons{
            li{ margin-left: 20px;}
        }
    }   
    
}

/*---------------------------*/


.bg-color {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: $bg-color;
}

.container-block {
    width: 100%;
    height: 100%;
    max-width: 750px;
    min-height: 100vh;
    padding: 30px;
    margin: auto;
    background-color: $aumnics-white;
    box-shadow: 0 0 10px $box-shadow-color;
}

.d-base {
    @include d-base;
}

.d-center {
    @include d-center;
}

.d-start {
    @include d-start;
}

.d-end {
    @include d-end;
}

.d-items {

    &-a,
    &-around {
        @include d-items-around;
    }

    &-b,
    &-between {
        @include d-items-between;
    }

    &-c,
    &-center {
        @include d-items-center;
    }

    &-e,
    &-evenly {
        @include d-items-evenly;
    }
}

.d-base {

    &-a,
    &-around {
        @include d-base-around;
    }

    &-b,
    &-between {
        @include d-base-between;
    }

    &-c,
    &-center {
        @include d-base-center;
    }

    &-e,
    &-evenly {
        @include d-base-evenly;
    }
}

.w- {

    &min {
        width: min-content;
    }

    &max {
        width: max-content;
    }

    &fit {
        width: fit-content;
    }
}

.mat-drawer-content{
  overflow: hidden !important;
}

 *,
 .custom-scroll,
 .modal {
     &::-webkit-scrollbar-track {
         margin: 2px;
         background-color: transparent;
         width: 30px;
     }

    &::-webkit-scrollbar {
        position: fixed;
        right: 10px;
        width: 9px;
        height: 10px;
      }

     &::-webkit-scrollbar-thumb {
         border-radius: 6px;
         background-color: #585858a1;
         min-height: 200px;
     }
     &::-webkit-scrollbar-thumb:hover {
         background-color: #555;
 }
 }

 .regular-scroll::-webkit-scrollbar, .modal::-webkit-scrollbar {
  width: 0px !important;
}

// .modal::-webkit-scrollbar-thumb {
//     background-color: $bg-color;
//     padding-right: 5px !important;
//     margin-right: 5px !important;
// }



html, body { height: 100%; }
body { margin: 0; font-family:"Geist", Roboto, sans-serif !important;
    letter-spacing: 0.4px; }

.mat-drawer-container { background-color:$aumnics-white !important;}

// .user-agreement {
//     background: $primary-color; display:grid; place-items: center; height: 180px;
//     h2.heading-one{ color: $aumnics-white; margin: 0;}
// }
.user-cont{
   // padding: 50px 0;
    p{ font-size: 18px; line-height: 30px; margin:0 0 30px;
    
    }.user-space{ margin-bottom: 50px;}
    h5{font-family: 'Geist'; font-size: 20px; margin: 0 0 8px;}
    .heading-space{ margin:0 0 30px;}
}

.cc-nb-main-container{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: $aumnics-white;box-shadow: -4px 3px 20px #00000029;
}

.termsfeed-com---nb .cc-nb-main-container{
    padding:20px 40px;
}

.termsfeed-com---palette-dark .cc-nb-title { font-weight: 500;}
.termsfeed-com---palette-dark .cc-nb-title, .termsfeed-com---palette-dark .cc-nb-text { color: #000; margin: 0 0 10px;}
.termsfeed-com---palette-dark .cc-nb-okagree {
    color: $aumnics-white;
    background-color: $primary-color;
    padding: 10px 20px;
    border: none; margin-right: 20px !important;font-weight: normal;border-radius: 4px;
}

.termsfeed-com---palette-dark .cc-nb-changep {
    background-color: transparent;
    color: $primary-color; font-weight: normal;
    padding: 10px 20px;
    border: $primary-color 1px solid; border-radius: 3px;
}

.termsfeed-com---pc-dialog { top: 20%;}
.mat-tooltip {
    font-size: 14px;
    background-color: $text-head-color;
}
.p-spacing{
    margin-bottom: 50px !important;
  }
  .tooltip--multiline{
    z-index: 10000;
}
.p-inputtext{
    padding: 0.7em !important;
}

.search-icon,
.clock-icon,
.description-icon {
  background-image: url("../icons/menu-searchIcon.svg");
  background-repeat: no-repeat;
  background-position: 15px;
  background-size: 22px;
  padding: 10px 15px 10px 50px;
  cursor: pointer;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-input .p-autocomplete {
    width: 100% !important;
    max-width: 423px !important;
}
.search-input .p-inputtext { 
    border-radius: 50px !important;
    padding-left: 50px !important;
    min-height: 50px !important;
    width: 100% !important;
    max-width: 423px !important;
    font-size: 15px !important;
    font-family: "Geist", Roboto, sans-serif !important;
}

.dev-short-name { 
    font-size: 20px !important;
}

.search-input ul li {
        min-height: 50px !important;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
}

.search-input .p-autocomplete .p-autocomplete-panel {
    max-height: 300px !important;
    max-width: -webkit-fill-available;
}

.search-input.p-inputwrapper-focus .p-autocomplete,.search-input.p-inputwrapper-focus .p-inputtext {
    width: 100% !important;
    max-width: 480px !important;
}

.seacrh-for-mobile .p-autocomplete, .seacrh-for-mobile .p-inputtext{
    width: 100% !important;
}

.fw-500{
    font-weight: 500;
}
// Define a map or a list of widths
$widths: 1100, 1000, 950, 900, 850, 800, 750, 700, 650, 600, 550, 500, 450, 400, 350, 300, 250, 200;

// Loop through each width and generate the classes dynamically
@each $width in $widths {
  .mw-#{$width} {
    max-width: #{$width}px;
  }
}

.sub-heading-color{
    color: $card-label-color;
}

.error-text.p-inputnumber{
    flex-direction: column !important;
  }

.thick-blue-border{
    border-bottom: 5px solid $primary-color !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.lottie-animation-star-l{
	div{
		width: 100px !important;
		height: 85px !important;
	}
}
.lottie-animation-star-s{
	div{
		width: 70px !important;
		height: 60px !important;
	}
}
.lottie-animation-star-l.lft{
    margin-right: 0px;
}
.lottie-animation-star-l.rgt{
    margin-left: -20px;
    margin-right: -32px;
}
.lottie-animation-star-s.lft{
    margin-right: -72px;
}
.lottie-animation-star-s.rgt{
    margin-right: -20px;
}
.lottie-animation-location div,.lottie-animation-search div{
    width: 53px!important;
    height: 44px!important;
}
.crossHireBlueArrow div{
    width: 70px !important;
}
.company_aumnics, .our-culture{
    .mat-expansion-panel{ box-shadow: none  !important; border: 1px solid #e8e8e8; margin: 20px 0;}
}
.our-culture .mat-expansion-panel-header { height: 80px;}
.phone-field{
    display: flex;
    .p-dropdown {width: 107px !important; margin-right: 10px;}
    #contactNumber{ width: 100%;}
  }
.card-label{
	color: $card-label-color;
    font-size: 15px;
}

.text-gray{
    color: $card-label-color;
}

.text-dark-gray {
  color: $dark-gray;
}

.res-card{ font-size: 15px;}
.res-card:hover { scale: 1.02; transition: all .4s ease-in-out;}
// .card:hover { scale: 1.01; transition: all .4s ease-in-out;}

.card-value{
    
    font-size: 14px;
    font-weight: 500;
    font-style: normal !important;
}
.star-icon{
    margin-bottom: 3px;
}
.review-sec{
    font-size: 13px !important;
}

.review-count{
    color: #767676;
}

.display-tag{
    padding: 10px 15px;
    border-radius: 15px;
    border: 1px solid #e8e8e8;
    margin: 0 10px 10px 0;
}
// .section-para{
// 	max-width: 823px;
// }


//cards

.bordered-card {
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 20px;
}

.section_bg {
  background: $section-bg-primary;
}


a.showMore-btn:hover { color: $aumnics-white !important; }
a.link-item { text-decoration: none; cursor: pointer; &:hover{ text-decoration: underline !important;}}

.showMore-btn-cancel.form-cancel{
    border: 1px solid $primary-color-2 !important;
    color: $primary-color-2 !important;
}


a.showMore-btn-cancel:hover { color: $primary-blue-color-2 !important; }

.showMore-btn-cancel.form-cancel:hover {
    color: $primary-color-2 !important;
}

.sec-padding{
    padding: 8rem 0;
}
.banner-sec {
    padding: 60px 0 !important;
}

.agreement-padding{
    padding: 60px 0;
}

.video-tab {
  padding: 10px 25px !important;
}

.loadMore-btn{
    white-space: nowrap;
    color: $primary-color;
    font-weight: bold;
    cursor: pointer;
    a{
        color: $primary-color;
    }
}
.profileLogo{
    min-width: 90px;
    min-height: 90px;
    width: -moz-max-content;
    width: max-content;
    height: -moz-max-content;
    height: max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    background-color: #D7E3EE;
}

.profileLogo-140{
    min-width: 140px !important;
    min-height: 140px !important;
}

.profileLogo-120{
    min-width: 120px !important;
    min-height: 120px !important;
}

.profileLogo-80{
    min-width: 80px !important;
    min-height: 80px !important;
}

.profileLogo-60{
    min-width: 60px !important;
    min-height: 60px !important;
}

.seacrh-profile {
    min-width: 35px;
    min-height: 35px;
    width: -moz-max-content;
    width: max-content;
    height: -moz-max-content;
    height: max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.seacrh-profile.package {
    border-radius: 0px !important;
}

.card-margin-container{
    margin-left: -1%;
    margin-right: -1%;
}
.cards-container {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.res-card {
  padding: 0 8px;
  margin: 1% 0.5%;
  box-sizing: border-box;
  flex: 24%;
  max-width: 24%;
  cursor: pointer;
}
a.res-card{
    color: $text-head-color;
}
.g-dot{
    position: absolute;
    background-color: $card-green;
    right: 6%;
    top: 0%;
    padding: 3.5%;
    border-radius: 25%;
}
.y-dot{
    position: absolute;
    background-color: $card-yellow;
    right: 0%;
    top: 0%;
    padding: 5%;
    border-bottom-left-radius: 40%;
    border-top-right-radius: 10px;
}

//backgriung of section
.sec-primary-background {
    background-color: $section-bg-primary;
}

.sec-secondary-background {
    background-color: $section-bg-secondary;
}

.sec-teritiary-background {
  background-color: $section-bg-teritiary;
}

.grayText, .grayText .fa-solid {
    color: $card-label-color !important;
}


.developer-card{
    border-radius: 10px;
}
.developer-card .content-div{
    max-width: 100%;
}

.p-breadcrumb ul li{
    white-space: nowrap !important;
}

.p-breadcrumb ul li:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
.p-breadcrumb{
    border: none !important;
    padding: 0 0 1rem!important;
}
.sub-btn a{
    color: $aumnics-white;
}

.border-round { border-radius: 50px;}
.border-radius { border-radius: 16px; }
.card-border { border-radius: 10px;}


.middle-cards-sec::-webkit-scrollbar {
    width: 12px; 
  }
  
  .middle-cards-sec::-webkit-scrollbar-thumb {
    background-color: $aumnics-white;
  }
  
  .middle-cards-sec::-webkit-scrollbar-track {
    background-color: $aumnics-white; 
  }

  .package-desc {
    min-height: 75px !important;
  }
  .card-title {

      display: inline;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      font-size: 18px !important;
  }

  //review stars
  .filledStar{
    color: $text-head-color !important;
  }
  .hallowStar{
    color: $aumnics-white !important;
  }

  .full-width-drawer{
    width: 100% !important;
}

.banner-sec {
    .p-breadcrumb ul li:last-child .p-menuitem-text, .p-breadcrumb ul li.p-breadcrumb-chevron ,
    .p-breadcrumb ul li.p-breadcrumb-chevron, .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
         color: $aumnics-white !important;}
    
    .p-breadcrumb{background-color: transparent !important; padding: 0 0 3rem !important;}
 }

 .banner-sec.black-text{
    .p-breadcrumb ul li:last-child .p-menuitem-text, .p-breadcrumb ul li.p-breadcrumb-chevron ,
    .p-breadcrumb ul li.p-breadcrumb-chevron, .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
         color: $text-head-color !important;}
    
    .p-breadcrumb{background-color: transparent !important; padding: 0 0 3rem !important;}
 }


//mcq-section
.mcqs-sec{
    .p-panel .p-panel-header ,.p-panel .p-panel-content{
        background-color: $aumnics-white !important;
        color: $text-head-color !important;
        border: none;
        font-family: "Geist", Roboto, sans-serif !important;
    }
 .p-panel .p-panel-header {
    padding: 1rem 2rem !important;
    font-size: 16px !important;
    font-weight: 500 !important;

  }
  
  .p-panel .p-panel-header .p-panel-title{
    font-weight: 500 !important;
    line-height: 28px !important;
  }

  .p-panel .p-panel-content {   
    padding: 0.5rem 2rem !important;
    border-bottom: none;
    border-radius: 0px !important;
  }

  .pi-minus {
    color: $nav-link-active-color !important
  }
  .p-panel .p-panel-header .p-panel-header-icon {
    box-shadow: none !important;
  }
  .p-panel-toggleable {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 25px;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
  }
}

.link{
    text-decoration: underline !important;
    cursor: pointer !important;
    color: $banners-background-color !important;
}

//packages listing headings
.listing-sec-head {
    padding: 0 !important;
    position: relative;
    .container {  z-index: 1;}
    img { height: auto; width: 100%;}
    .list-content-sec { padding: 60px 0px;}
    h3.heading-one {
        display: flex;
        span {
          font-size: 20px !important;
          padding-top: 3px;
          margin-left: 20px;
        }
    }
}

.listing-package-sec { 
    border-radius: 20px; 
    padding: 15px !important;
}


//listing sub heading section
.listing-sec-sub-head-sec {
    margin: 40px 0 20px 0;
}

.listing-sec-head::after{
    content: "";
    display: block;
    width: 30%;
    height: 70%;
    top: 30%;
    left: 0;
    position: absolute;
    border-radius: 0% 100% 100% 0% / 0% 100% 0% 100%  ;    
}

.listing-sec-head.packages.listing-package-sec::after{
    width: 30%;
    height: 100%;
    top: 0;
    right: 0;
    left: auto;
    border-radius: 65% 0% 26% 31% / 100% 73% 0% 0% ;
    border-top-right-radius:20px ;
    border-bottom-right-radius: 20px;
}

.listing-sec-head.packages {
    background-color: #DBF2EA;
}
 .listing-sec-head.packages::after{
     background: #e5f6f0;
 }

.listing-sec-head.agencies {
    background-color: #E6E5FF;
}
.listing-sec-head.agencies::after{
     background: #ebeaff;
}

.listing-sec-head {
    .p-breadcrumb ul li:last-child .p-menuitem-text, .p-breadcrumb ul li.p-breadcrumb-chevron ,
    .p-breadcrumb ul li.p-breadcrumb-chevron, .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
         color: $text-head-color !important;}
    
    .p-breadcrumb{background-color: transparent !important;padding: 0 0 30px !important;}
}

.listing-sec-head.skills {
    background-color: #DCC3FF;
}
 .listing-sec-head.skills::after{
     background: #e6d4ff;
     }

.show-on-mobile { display: none;}

//home nav bard
.mobile-search{
	display: none;
}

//mat tabs
.mat-tab-body-wrapper { 
    display: none !important;
}

.mat-tab-labels .mat-tab-label {
    border-radius: 50px !important;
    padding: 10px 20px !important;
    color: $text-head-color !important;
    background-color: #e9e4e421 !important;
    font-size: 20px !important;
    height: 42px !important;
    margin-right: 20px !important;
    opacity: 1 !important;
    font-weight: 400 !important;
    border: 1px solid #80808028 !important;
    font-family: "Geist", Roboto, sans-serif !important;
}

.mat-tab-labels .mat-tab-label.mat-tab-label-active {
    border: none !important;
    color: $aumnics-white !important;
    background-color: $primary-color !important;
}

.mat-tab-header { border: none !important;
    button:disabled{ display: none !important}
}
.mat-tab-group.mat-primary .mat-ink-bar { display: none !important;}


.mat-tab-header-pagination-before ,.mat-tab-header-pagination-after{
   display: none !important;
}

.square-tabs {

  .mat-tab-list {
    background-color: #f7f7f7;
    border-radius: 6px;
  }

  .mat-tab-labels {
    justify-content: space-between !important;
  }

  .mat-tab-labels .mat-tab-label {
    border-radius: 6px !important;
    background-color: transparent !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: $sub-heading-color !important;
  }

  .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    border: 2px solid $border-color !important;
    color: $title-color !important;
    background-color: $aumnics-white !important;
  }
}


//ng dropdown css
.ng-select .ng-select-container  {
    height : 52px !important
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input , 
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{
    top: 15px;
}

.ng-select .ng-value-container { 
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

// #bookADemoForm .col {
//     padding: 0 !important;
//     margin-left: .5rem;
//     margin-right: .5rem;;
// }

#bookADemoForm .field {
    margin-bottom: 1rem !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px !important;
}

.ng-select-opened {
    border: 1px solid $aumnics-black !important;
}

// Sprite images css

.home-sec-listing-bg {
	width: 250px; height: 300px;
    margin-top: -40px;
    margin-bottom: -40px;
	scale: 1.2;
    background-repeat: no-repeat !important;
}

.listing-package-sec .home-sec-listing-bg {
    width: 243px; height: 193px;
    scale: 0.6;
}

.banner-sec-image-bg {
    width: 500px; height: 500px;
    margin-top: -100px; margin-bottom: -100px;
    scale: 0.6;
}


.card-image-sec-bg {
    width: 200px; height: 235px;
    margin-top: -30px; margin-bottom: -30px;
    scale: 0.6;
}

.lead-team-sec-bg {
    width: 160px;
    height: 160px;
    margin-top: -30px;
    margin-bottom: -30px;
    scale: .6;
}

.aboutus-full-img-bg {
    width: 300px; height: 300px;
    margin-top: -30px; margin-bottom: -30px;
    scale: 0.6;
}


.samll-card-image-sec-bg {
    width: 135px;
    height: 117px;
    margin-top: -30px;
    margin-bottom: -30px;
    scale: .6;
}

.journey-image-sec-bg {
    width: 110px;
    height: 183px;
    margin-bottom: -45px;
    scale: 1;
    background-repeat: no-repeat !important;
}


.contact-us-cards-bg {
    width: 156px;
    height: 156px;
    margin-top: -10px;
    margin-bottom: -30px;
    scale: .8;
}

.contact-us-clocks-bg {
    width: 96px;
    height: 96px;
    margin-top: -10px;
    margin-bottom: -0px;
    scale: .8;
}

.affiliate-cards-bg {
    width: 120px;
    height: 120px;
    margin-top: -10px;
    margin-bottom: -30px;
    scale: .8;
}

.match-customers-bg-card {
    width: 72px;
    height: 66px;
    margin-top: -30px;
    margin-bottom: -30px;
    scale: .8;
}

.why-amy-cards {
  border-radius: 16px;
  padding: 30px;
  height: 100%;
  transition: all 0.1s ease-in-out;

  &:hover {
    box-shadow: $card-hover-shadow;
    transform: translateY(-2px); // Slight lift effect on hover
    background: linear-gradient(to right, #faf5ff, #eef2ff); /* from purple-50 to indigo-50 */
    .rounded-img-bg {
      transform: scale(1.1);
    }
  }
}

.cfa-sec {
  position: relative;
  background: linear-gradient(to bottom right, #1e3a8a, /* from-blue-950 */  #6b21a8, /* via-purple-900 */ #1e40af); /* to-blue-800 */
  .cfa-grid {
    position: absolute; 
    inset: 0;
    background: linear-gradient(to right, #4f4f4f2e 1px, transparent 1px),
              linear-gradient(to bottom, #4f4f4f2e 1px, transparent 1px); 
  
    background-size: 4rem 4rem;
    
    -webkit-mask-image: radial-gradient(ellipse 80% 80% at 50% 50%, #000 70%, transparent 100%);
    mask-image: radial-gradient(ellipse 80% 80% at 50% 50%, #000 70%, transparent 100%);
  }

  .whats-new-label.dark-banner {
    background-color: #ffffff1a !important;
    backdrop-filter: blur(8px) !important;
    border: 1px solid #ffffff33 !important;
    .wave-dot {
      background: $aumnics-white !important;
    }
    .paragraph-global-text{
      color: $aumnics-white !important;
    }
  }

  .showMore-btn-cancel.white-btn:hover {
    background-color: #4f2b9d !important;
    color: $aumnics-white !important;
  }
}

.wavy-line-image {
  position: absolute;
  top: 8%;
  left: 0;
}

.explore-countries-banner-img{
    width: 741px;
    max-width: 100%;
    height: 100%;
    min-height: 541px;
    margin-top: 0;
    margin-bottom: 0;
    scale: 1;
}

.app-benefits-bg {
    width: 85px;
    height: 85px;
    margin-top: -10px;
    margin-bottom: 0;
    margin-left: -10px;
    scale: 0.8;
}

.app-integrations-bg {
    width: 100px;
    height: 95px;
    margin-top: -10px;
    margin-bottom: 0;
    scale: .8;
    margin-left: -10px;
}

.cms-features-bg {
    width: 90px;
    height: 64px;
    margin-top: -10px;
    margin-bottom: 0;
    scale: .8;
    margin-left: -10px;
}

.custom-packages-bg {
    width: 90px;
    height: 80px;
    margin-top: -10px;
    margin-bottom: 0;
    scale: .8;
    margin-left: -10px;
}

.packages-benefits-bg {
    width: 145px;
    height: 182px;
    margin-top: -10px;
    margin-bottom: 0;
    scale: .8;
    margin-left: -10px;
}

.banner-dots-background {
    background-image: radial-gradient(#adadad7d 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -19px -19px;
    width: 100%;
    height: 100%;
}

.light-banners-dots-background {
    background-image: radial-gradient(#cbcbcb7d 1px , transparent 0);
    background-size: 10px 10px;
    background-position: -19px -19px;
    width: 100%;
    height: 100%;
}
.dots-background {
    background-image: radial-gradient(#acacac73 1px, transparent 0);
    background-size: 10px 10px;
    background-position: -19px -19px;
    width: 100%;
    height: 100%;
}


//   tabs scroll css
.mat-tab-label-container {
    overflow: scroll !important;
    scrollbar-width: none !important;
    /* Firefox */
    -ms-overflow-style: none !important;
    /* IE/Edge */
}

.mat-tab-label-container::-webkit-scrollbar {
    display: none !important;
}

/* Color gradients top to bottom */

.blue-gradient {
    background-image: linear-gradient(#6C5AF9, #e0dbff);
}

.orange-gradient {
    background-image: linear-gradient(#FBCC5B, #fff6eb);
}

.green-gradient {
    background-image: linear-gradient(#85CA7C, #ccffe5);
}

.banner-input {
    font-size: 15px !important;
    border-radius: 12px !important;
    // max-width: 550px !important;
}

.banner-input.homepage-input {
  background-color: #ffffff0d !important;
  border: 1px solid #4b5563 !important;
  color: $aumnics-white !important;
  border-radius: 8px !important;
}

.banner-input.homepage-input:focus{
  outline: 2px solid $cfa-btn-color !important;
}

.banner-input-btn {
    padding: 14px 30px !important;
}

.anchor-text,.anchor-text:hover{
  color: $text-head-color;
}

.chat-bubble {
    position: fixed;
    bottom: 95px;
    right: -70px;
    z-index: 9999;
    /* background-color: #f1f1f1; */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    transition: transform 300ms ease 0s, opacity 300ms ease 0s;
}

.box-shadow {
  box-shadow: 0 0 10px $box-shadow-color;
}

.chat-bubble-2 {
    position: fixed;
    bottom: 166px;
    right: -78px;
    z-index: 9999;
    /* background-color: #f1f1f1; */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    transition: transform 300ms ease 0s, opacity 300ms ease 0s;
}

.triangle {
    position: absolute;
    top: 100%;
    left: calc(50% + 65px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    /* border-top: 10px solid #f1f1f1; */
}

.content {
    color: black;
    padding: 5px 10px;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

/* Customize the chat container with animation */
.bp-webchat {
    width: 300px;
    height: 400px;
    border: 2px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    animation: slideIn 0.5s ease-in-out;
}

.cancel_btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid $amy-bot-border-color;
    font-size: 14px !important;
    cursor: pointer;
    width: fit-content;
    color: #000000;
    border: 1px solid $amy-bot-border-color;
}

.cancel_btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid $amy-bot-border-color;
    font-size: 14px !important;
    cursor: pointer;
    width: fit-content;
    color: #000000;
    border: 1px solid $amy-bot-border-color;
}

#cancel_btn2, #cancel_btn3{
    position: fixed;
    bottom: 325px;
    right: 0px;
    padding: 10px;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.d-flex {
    display: flex;
}

.pdf {
    width: 40px;
    margin-left: 7px;
}

.download_report_btn {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $amy-bot-border-color;
    font-size: 14px;
    align-items: center;
    display: flex;
    margin: auto;
    justify-content: center;
    width: fit-content;
    padding: 5px;
    border-radius: 7px;
}

.text p {
    margin-top: 0px;
}

.text {
    margin-left: 10px;
}

#service_provider_1 {
    bottom: 290px;
    max-width: 300px;
    right: -115px !important;
    background-color: $aumnics-white;
}
#service_provider, #glad_to_see, #client {
    background-color: $aumnics-white;
}
#glad_to_see {
    max-width: 300px;
    right: -115px !important;
    bottom: 212px;
}

.download_report {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: 1px solid $amy-bot-border-color;
    width: fit-content;
    padding: 6px 17px;
    border-radius: 50px;
    cursor: pointer;
    color: $aumnics-white !important;
    
}

.close_icon {
    border-radius: 50px;
    /* top: -55px; */
    // right: -10px;
    height: fit-content;
    position: relative;
    width: fit-content;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    background-color: #fff;
}

#cancel_btn {
    position: fixed;
    bottom: 555px;
    right: 0px;
    padding: 10px;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.cc-right{
    right: auto !important;
}

.cc-bottom {
    bottom: 3em !important;
}

.overlay-msg {
    transition: transform 0.5s ease-in-out;
}

.z-index{
    position: relative;
    z-index: 1;
}


.p-carousel-indicators{
    display: none !important;
  }

  .p-carousel-item {
    display: flex;
    align-items: center;
  }

  .matte-bg {
    background: #272931;
  }

  .no-padding-bottom {
    padding-bottom: 0 !important;
  }

  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img{
    height: 28% !important;
    width: 85% !important;
  }
  .ng-image-slider .ng-image-slider-container .main .main-inner .img-div .caption {
    display: none;
  }

  .seo-hyperlink{
    color: $primary-color-2;
  }

  .user-agreement {
    dl, ol, ul {
        margin-left: 35px !important;
        list-style-type: disc;
        vertical-align: top;
    }
  }

  .user-agreement ul ul {
    list-style-type: circle;
  }
   .user-agreement p{
    vertical-align: top;
    margin-top: -2px;
   }  

  .custom-list {
    list-style: none;
    counter-reset: custom-counter;
  }

  .custom-list a { color: $primary-color-2;}
  
  .custom-list > li {
    counter-increment: custom-counter !important;
    margin-bottom: 18px !important;
  }
  
  .custom-list > li::before {
    content: counters(custom-counter, ".") ". " !important;
    margin-left: -23px !important;
  }
  
  .custom-list[type="a"] > li::before {
    content: counter(custom-counter, lower-alpha) ". " !important;
    margin-left: -23px !important;
  }
  
  .custom-list[type="i"] > li::before {
    content: counter(custom-counter, lower-roman) ". " !important;
    margin-left: -23px !important;
  }

  .second-level {
    padding-left: 7% !important;
  }

  .third-level {
    padding-left: 8% !important;
  }


  ol{
    padding-top: 20px !important;
  }

  .table-sec {
    .col-4 { border: 1px solid $border-color !important; border-top: none !important;}
    .col-8 { border: 1px solid $border-color !important; border-top: none !important;border-left: none !important;}
    .top-div {border-top: 1px solid $border-color !important;}
  }

  .nav-bar-expansion.mat-card , .nav-bar-expansion .mat-expansion-panel{
    box-shadow: none !important;
  }

  .nav-bar-expansion .mat-expansion-panel-header{
    padding: 0 !important;
    align-items: flex-start !important;
  }

  .nav-bar-expansion .mat-expansion-indicator {
    padding: 0px 10px !important;
  }
  .nav-bar-expansion .mat-expansion-indicator::after{
    padding: 5px !important;
    color: $nav-links-color !important;
  }

.modal {
    background-color: #2d2d2d75;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px !important;
}

.modal-backdrop {
    z-index: 1;
    display: none;
}

.modal-dialog {
    max-width: 800px;
}

.modal-full {
    .modal-dialog {
        max-width: 100% !important;
        margin: unset;
    }

    .modal-content {
        min-height: 100vh;
    }

    [class*="modal-w-"] {
        .modal-dialog {
            margin: 20px auto;
        }

        .modal-content {
            min-height: auto;
        }
    }
}

$modalWidths: (
    400,
    500,
    600,
    700,
    800,
    900
);

@each $key in $modalWidths {
    .modal-w-#{$key} {
        .modal-dialog {
            max-width: $key + px !important;
        }
    }
}

.modal .min-height-0 {
    min-height: unset !important;
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        width: 90%;
    }

    .sm-full-modal {
        .modal-dialog {
            width: 100% !important;
        }
    }

    iframe#featureVideoPlayer{
      max-height: 350px !important;
  }
}

.modal-body {
    min-height: 200px;
}

.modal.fade .modal-dialog {
    transition: transform .1s ease-out;
}

.success-modal {
    .modal-body {
        min-height: 400px;
    }
}

.pull-right {
    float: right !important;
}


.cancelCrossIcon {
  cursor: pointer;
  float: right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 5px;
  height: 20px;
  width: 20px;
}

.cancelCrossIcon {
  opacity: 0.7;
  width: 18px;
  height: 18px;
  background-image: url("../icons/delete-cross.svg");
}

.cancelCrossIcon:hover {
  opacity: 0.9;
}

.modal {
  .cancelCrossIcon {
    width: 16px;
    height: 16px;
  }
}
@media screen and (min-width: 1250.1px) {

    #projectPostingTypeModal,
    #newProjectPostingModal {
        .modal-dialog {
            max-width: 1250px !important;
        }
    }
}

.modal-header {
    padding: 18px 20px 7px !important
}
.p-absolute {
    position: absolute !important;
}

.modal-open {

    .cc-floating,
    .chat-bot {
        display: none;
    }
}

.modal:focus {

    .cc-floating,
    .chat-bot {
        display: none;
    }
}
.bottom-sticky-div {
    position: sticky !important;
    bottom: 0 !important;
    right: 0 !important;
    background: #fff !important;
}

.model-bottom-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mat-tab-labels{
  justify-content: center;
}

.mat-tab-labels > *:last-child {
  margin-right: 0 !important;
}
.pitch-quill-description{
  ul{
    list-style-type:  disc !important;
    margin-left: 20px;
  }
}

.mr-30{
    margin-right: 30px !important;
}

// book demo css
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $nav-link-active-color !important;
}
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $nav-link-active-color !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $nav-link-active-color !important;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $nav-link-active-color !important;
    background: $nav-link-active-color !important;
}
.black{
    color: $black !important;
}

.primary-color {
    color: $primary-blue-color-2 !important;
}

.secondary-bg-color {
    background-color: $secondary-bg-color!important;
}

.skill-quill-content {

    ol,
    ul,li
    p {
        list-style: auto;
        line-height: 1.8;
        font-size: 16px !important;
    }
    h1{
        font-size: 40px !important;
        margin-bottom: 10px !important;
        line-height: 1.2 !important;
        letter-spacing: 0.7px !important;
}
    
    h2{
        font-size: 28px !important;
        margin-bottom: 10px !important;
        line-height: 1.2 !important;
        letter-spacing: 0.7px !important;
    }
    h3{
        font-size: 22px !important;
        margin-bottom: 10px !important;
        line-height: 1.2 !important;
        letter-spacing: 0.7px !important;
}
img{
    max-width: -webkit-fill-available !important;
}
a{
    word-break: break-all;
}
pre {
    background-color: $pre-background;
    border: 1px solid $pre-border;
    margin-bottom: 0rem;
    padding: 5px;
    border-radius: 3px;
    color: $text-head-color;
    white-space: pre-wrap !important;
    font-size: 16px !important;
  }
  p{
    text-align: justify;
 }
 p strong, s, a, ul, li, ol, span{
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  ul{
    list-style: disc !important;
    margin-left: 18px;
  }
  ol{
    margin-left: 18px;
    padding-top: 0px !important;
  }
}



.subscription-accordian .p-accordion .p-accordion-header .p-accordion-header-link {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: #40576d12;
    &:hover{
      background-color: $cardsHoverBg !important;
    }
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus { box-shadow: none;}
  .subscription-accordian .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link ,
  .subscription-accordian .p-accordion p-accordiontab:last-child .p-accordion-content{
    border: 1px solid $border-color;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  
  .subscription-accordian .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    border: 1px solid $border-color;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .subscription-accordian .p-accordion .p-accordion-header .p-accordion-header-link {
    .pi {
      font-size: 20px !important;
      color: $text-head-color !important;
    }
  }
  
  .subscription-accordian .p-accordion .p-accordion-content {
    padding: 0 20px;
    span {
        font-size: 14px;
        color: $text-head-color;
    }
  }
  

  .billing-tabs {
    display: flex;
    margin-top: 5px;
  
    p {
      display: flex;
      align-items: center;
      word-break: unset !important;
      cursor: pointer;
    }
  }

  
//latest serach common css
.latest-search{
    border-radius: 9px !important;
    height: 38px !important;
    background-image: url("./../icons/menu-searchIcon.svg");
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 17px;
    padding: 10px 15px 10px 42px;
  }
  //latest serach common css
  .task-manager-search{
    border-radius: .375rem !important;
    height: 35px !important;
    background-image: url("./../icons/menu-searchIcon.svg");
    background-repeat: no-repeat;
    background-position: 11px;
    background-size: 16px;
    padding: 10px 15px 10px 42px;
    cursor: pointer;
  }
  

.task-manager-search.form-control, .latest-search.form-control {
    display: block;
    width: 100%;
    height: 38px;
    padding: 5px 16px;
    font-size: 15px;
    line-height: 1.42857143;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    border: 1px solid $new-border-color !important;
    border-radius: 5px !important;
    color: $text-head-color !important;
    font-weight: $font-weight-400 !important;
    margin-right: 3px;
  
    &:focus {
      box-shadow: none !important;
    }
  }

    //latest serach common css
.task-manager-search{
    border-radius: .375rem !important;
    height: 35px !important;
    background-image: url("./../icons/menu-searchIcon.svg");
    background-repeat: no-repeat;
    background-position: 11px;
    background-size: 16px;
    padding: 10px 15px 10px 42px !important;
    background-color: $aumnics-white !important;
    cursor: pointer;
}

.f-20 {
    font-size: 20px !important;    
}

.f-19 {
    font-size: 19px !important;    
}

.f-18 {
    font-size: 18px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-12 { font-size: 12px !important;}
.f-14 { font-size: 14px !important;}

.p-body-0.p-dialog .p-dialog-content {
    padding: 0 !important;
}

.p-dialog {
    border-radius: 16px;
}

.p-dialog-header {
    border-bottom: 1px solid $border-color !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon{
    border-radius: 16px;
}

.round-corners {
    border-radius: 16px;
}

.task-priority-panel.p-overlaypanel .p-overlaypanel-content {
    padding: 0px !important;
  }
  
  .task-assign-panel .p-panel-cards-styles {
    padding: 6px 8px !important;
  
    .profile-assign-btn {
      display: none;
    }
  }
  
  .task-assign-panel .p-panel-cards-styles:hover {
    .profile-assign-btn {
      display: block;
    }
  }
  
  .fromGoals.p-overlaypanel {
    border-radius: 6px !important;
    overflow: auto;
  }
  
  .task-tags-panel .p-panel-cards-styles {
    padding: 0px 8px !important;
    margin: 4px 0px !important;
    border-radius: 0px !important;
  
    .profile-assign-btn {
      display: none;
    }
  }
  
  .task-tags-panel .p-panel-cards-styles:hover {
    border-radius: 0px !important;
  
    .profile-assign-btn {
      display: block;
    }
  
  }
  
  .fromNewTaskManager.p-overlaypanel {
    border-radius: .625rem !important;
  }
  
  
  .fromNewTaskManager.p-overlaypanel-flipped:after,
  .p-overlaypanel-flipped:before {
    bottom: 0% !important;
    top: 0% !important;
  }
  
  .fromNewTaskManager.p-overlaypanel:after,
  .p-overlaypanel:before {
    bottom: 0% !important;
    border-bottom-color: transparent;
    display: none;
  }
  
  .fromNewTaskManagerLeftArrowHide.p-overlaypanel:after,
  .p-overlaypanel:before {
    left: 7% !important;
  }
  
  .p-overlaypanel::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999; /* Adjust as necessary */
  }
  
  .fromNewTaskManager.tasks-ading-input-field {
    .form-control {
      padding: 5px 7px !important;
    }
  }
  
  .p-overlaypanel {
    box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.2) !important;
  }
  
  .max-w {
    width: -webkit-fill-available !important; /* For WebKit-based browsers (Chrome, Safari) */
    width: -moz-available !important;
  }

  .fa , .pi {
    color: $new-icon-default-color;
    cursor: pointer;
}
  
  
.now-wrap{
    white-space: nowrap !important;
  }
  .desc-1, .fc-toolbar-title{
    display: inline;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
  
  .desc-2 {
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
  
  .desc-3 {
    display: inline;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .limit-1, .limit-2, .limit-3, .limit-4 {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
  .limit-1 {
    -webkit-line-clamp: 1;
  }
  
  .limit-2 {
    -webkit-line-clamp: 2;
  }
  
  .limit-3 {
    -webkit-line-clamp: 3;
  }
  
  .limit-4 {
    -webkit-line-clamp: 4;
  }

  .drag-icon-2 {
    display: block;
  }

  .new-custom-scroll-content{
    overflow: scroll;
  }
  .new-custom-scroll-content::-webkit-scrollbar {
    width: 8px;
    height: 16px; 
  }
  .new-custom-scroll-content::-webkit-scrollbar-thumb {
    background-color: $aumnics-white;
    border-radius: 4px; 
  }
  .new-custom-scroll-content:hover::-webkit-scrollbar-thumb {
    background-color: $scroll-thumb;
  }


  .custom-input-switch {
    display: inline-block;
    transform: scale(0.8); /* Adjust the scale as needed */
  }
  
  .custom-input-switch .p-inputswitch {
    width:27px !important; /* Adjust width as needed */
    height: 17px !important;
  }
  
  .custom-input-switch .p-inputswitch-slider {
    width: 27px !important; /* Adjust width as needed */
    height: 17px !important;
    left: 0px !important;
    top: 3px !important;
  }
  
  .custom-input-switch .p-inputswitch-slider:before {
    width: 0.75em !important; /* Adjust knob width as needed */
   height: 0.75em !important; /* Adjust knob height as needed */
   top: 12px !important;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    left: -8px !important;
  }

  .p-checkbox , .p-checkbox .p-checkbox-box {
    width: 18px;
    height: 18px;
  }

  /* Default scrollbar styles */
.overflow-y-scroll {
    overflow-y: scroll;
  }
  .overflow-y-scroll::-webkit-scrollbar-track {
    background-color: $aumnics-white; 
  }
  
  .overflow-y-scroll::-webkit-scrollbar-thumb {
    background-color: $aumnics-white;
  }
  
  .overflow-y-scroll:hover::-webkit-scrollbar-thumb {
    background-color: #888; 
  }

  .dynamic-quill {
    margin: 12px;
    p {
        margin-bottom: 0px !important;
    }
  }


  
.ng-tooltip {
    position: absolute;
    text-align: center;
    background-color: #000000f1; /* Dark background */
    color: $aumnics-white;
    padding: 6px 13px;
    font-size: 13px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    font-weight: 400;
    z-index: 9999;
    opacity: 0;
    max-width: 300px;
  }
  .ng-tooltip:after {
    content: "";
    position: absolute;
    border-style: solid;
  }
  
  .ng-tooltip-top:after {
    top: 100%;
    left: 50%;
    margin-left: -4px;
    border-width: 4px;
    border-color: black transparent transparent transparent;
  }
  .ng-tooltip-bottom:after {
    bottom: 100%;
    left: 50%;
    margin-left: -4px;
    border-width: 4px;
    border-color: transparent transparent black transparent;
  }
  .ng-tooltip-left:after {
    top: 50%;
    left: 100%;
    margin-top: -4px;
    border-width: 4px;
    border-color: transparent transparent transparent black;
  }
  .ng-tooltip-right:after {
    top: 50%;
    right: 100%;
    margin-top: -4px;
    border-width: 4px;
    border-color: transparent black transparent transparent;
  }
  .ng-tooltip-show {
    opacity: 1;
  }

  .w-fit-content {
    width: fit-content !important;
  }
  .h-100{
    height: 100%;
  }

  .stopPointerEvents {
    pointer-events: none !important;
  }

  .col-5 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .fileSearchDiv {
    position: relative;
  
    .fileSearchField {
      padding: 4.5px 30px 5px 36px;
      background-size: 15px;
      background-position: 10px;
      background-color: $aumnics-white;
      text-decoration: none;
      border: 1px solid $border-color;
      border-radius: 9px !important;
      max-height: 35px;
      outline: none;
      color: $title-color;
      width: 100%;
  
      &::placeholder {
        font-size: 12px;
      }
    }
  
    .closeIcon {
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }

  .transparent-bg {
    background-color: transparent !important;
    background: transparent !important;
  }

  .p-dialog .p-dialog-header  {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .p-dialog .p-dialog-content:last-of-type  {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .p-toast-message-content {
    align-items: center !important;
  }

  .whats-new-label {
    padding: 6px 20px;
    background-color: $icon-bg-color;
    border-radius: 50px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-bottom: 25px;
    font-weight: 600;

    .paragraph-global-text {
      font-size: 15px !important;
      color: $primary-blue-color-2;
    }

    &:hover {
      // background-color: $secondary-bg-color;
      box-shadow: 0 0 10px $box-shadow-color;
    }
  }

  .dark-banner.whats-new-label {
    background-color: $aumnics-white;
  }

  .bordered.whats-new-label {
    border: 1px solid $primary-blue-color-2;
    background-color: transparent;
  }

  .whats-new-label.transparent-bg {
    background-color: transparent;
    border: 1px solid $aumnics-white;
  }

  .white-links-header {
    .nav-link, .nav-item .pi-angle-down{
      color: $aumnics-white !important;
    }
  
    .header-nav .navbar-nav .login .nav-link {
      border: 1px solid $aumnics-white !important;
      color: $aumnics-white !important;
    }
  
    .nav-item.signup .nav-link{
      border: 1px solid $aumnics-white !important;
      background-color: $aumnics-white !important;
      color: $primary-blue-color-2 !important;
    }
  }

  .registration-input.form-control {
    border-radius: 8px !important;
    // background-color: #6260a7 !important;
    // border: 2px solid $aumnics-white !important;
    // color: $aumnics-white !important; /* Set the text color to white */
  }
  
  /* Change placeholder color to white */
  .registration-input.form-control::placeholder {
    // color: $aumnics-white; /* Placeholder color */
    opacity: 1; /* Ensure opacity is 100% so that the white color is visible */
  }
  

  .header-nav .nav-item:hover{
		.nav-link .pi.pi-angle-down  {
			color: $primary-blue-color-2 !important;
		}	
	}

  .white-links-header .header-nav .nav-item:hover{
    .nav-link .pi.pi-angle-down  {
			color: $aumnics-white !important;
		}	
	}

  .card-v-gap {
    gap: 30px 0;
  }

  .ol-attribution.ol-unselectable.ol-control.ol-uncollapsible {
    display: none;
  }

  .p-dropdown .p-dropdown-panel {
    padding: 8px;
  }

  .p-dropdown-panel .p-dropdown-item.p-highlight {
    background-color: $primary-blue-color-2 !important;
  }

  .p-dropdown .p-dropdown-panel .p-dropdown-item{
    height: 40px !important;
    padding: 0px 15px !important;
    display: flex;
    align-items: center;
    margin: 2px;
    border-radius: 8px;
}

.ol-viewport {
  border-radius: 20px;
}

.btn-radius {
  border-radius: 6px !important;
}


.task-assign-panel .p-panel-cards-styles {
  padding: 6px 8px !important;

  .profile-assign-btn {
    display: none;
  }
}

.task-assign-panel .p-panel-cards-styles:hover {
  .profile-assign-btn {
    display: block;
  }
}

.regular-options-scroll .custombar2.scroll-content {
  max-height: 300px !important;
  overflow-y: scroll;
}

.task-assign-panel.industries{
  overflow-y: scroll; 
  .p-overlaypanel-content{
    padding: 0px !important;
  }
  .category-option{
    padding: 5px !important;
    cursor: pointer;
  }
}

.card-hover-effect{
  transition-property: box-shadow, transform;  // Smooth transition for shadow and transform
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  &:hover {
    box-shadow: $card-hover-shadow;
    transform: translateY(-2px);  // Slight lift effect on hover
    background: linear-gradient(to right, #faf5ff, #eef2ff); /* from purple-50 to indigo-50 */
    .rounded-img-bg {
      transform: scale(1.1);
    }
  }
}

 /* Highlight the selected image */
 .filter-blue {
  filter: invert(23%) sepia(64%) saturate(2292%) hue-rotate(225deg) brightness(99%) contrast(99%);
}


/* scrollable-cards.component.scss */
.scrollable-cards-container {
  position: relative;
 
.card-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
  will-change: transform;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.card {
  width: 100%;
  overflow: hidden;
  border: none;
  padding: 3rem 0;
  will-change: transform;
  transform-style: preserve-3d;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
}

.card-content {
  display: flex;
}

.image-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}
}  


.dot-pattern {
  width: 100%; /* Set your desired width */
  // height: 100%; /* Set your desired height */
  background-image: radial-gradient(circle, #00000029 1px, transparent 1px);
  background-size: 30px 30px;
}

.rounded-img { border-radius: 8px !important;}

.rounded-img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $icon-bg-color !important;
  border-radius: 1rem;
  width: 3rem;
  height: 3rem;
  padding: .75rem !important;
  img , svg{
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    background-color: $icon-bg-color-hover !important;
  }
}

.icon-sm.rounded-img-bg {
  img, svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.icon-lg.rounded-img-bg {
  img, svg {
    width: 2rem;
    height: 2rem;
  }
}

// waves animation in banner 
.wave-container {
  position: relative;
  display: block;
  width: 5px; /* Adjusted to fit a small dot */
  height: 5px; /* Adjusted to fit a small dot */
  margin: 4px 15px 2px 0;
}

.wave-dot {
  display: block;
  height: 4px; /* Small size */
  width: 4px;  /* Small size */
  border-radius: 50%;
  background: #5F54EE;
  transition: 5s ease;
  position: absolute;
  top: 0px;
  animation: wave-animation 2.5s linear infinite;
}

.white-waves .wave-dot {
  background: $aumnics-white;
}

@keyframes wave-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.wave-dot-1 {
  animation-delay: 0.1s;
}

.wave-dot-2 {
  animation-delay: 0.7s;
}

.wave-dot-3 {
  animation-delay: 1.3s;
}

.wave-dot-4 {
  animation-delay: 1.9s;
}

.transparent-bg {
  background-color: transparent !important;
}

.disc-list {
  margin-left: 30px;
  li.paragraph-global-text {
      display: list-item;
      list-style: disc;
  }
}

.howItWorks-container {
  .p-badge.p-badge-info {
    background-color: $primary-blue-color-2 !important;
    font-size: 1rem;
    font-weight: 700;
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .p-overlay-badge .p-badge {
    right: 2.5rem;
  }
  @media (min-width: 998px) {
      .lft-img-card {
          margin-right: -1.5rem;
          position: relative;
      }
      .left-badge.p-overlay-badge .p-badge {
        position: absolute;
        top: -5px;
        left: -5px;
        right: auto;                
        transform: translate(50%, -50%);
    }
  }
}

.button-container {
  @media (max-width: 768px) {
    .banner-input, .input-form{
      max-width: 100% !important;
    }
  }
}

.tab-content .lucide {
  color: $aumnics-white;
}

.background-img-div-wth-bg {
  background-color: #070728; 
  overflow: hidden;
  .bg-content-section{
    background-image: url('../../assets/images/layout-icons/section-bg-image.svg'); 
    background-size: cover; 
    background-position: center; 
    background-blend-mode: overlay;
  }
}

/* Items that will fade up */
.fade-up-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* When visible, the element is in place and fully opaque */
.fade-up-visible {
  opacity: 1;
  transform: translateY(0);
}


// navbar 
.nav-menu-icon {
	width: fit-content;
	height: fit-content;
	margin-right: 12px;
	padding: 8px;
	border-radius: 8px;
	svg { 
		width: 18px;
		height: 18px;
    margin-right: 0;
    padding: 0;
    border-radius: 0;
	}
}
.font-30{
  font-size: 30px !important;
}
.line-height-1-75{
  line-height: 1.75rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.hover\:text-violet-600:hover {
  --tw-text-opacity: 1;
  color: #7c3aed;
  color: rgb(124 58 237 / var(--tw-text-opacity, 1)) !important;
}