@media (min-width: 2100px) {

}

@media (min-width: 1801px) {
    .banner {height: 675px !important;}    
    .company-purpose p{ margin-bottom: 47px !important;}

    .mw-sticky-container { max-width: 800px !important;}
    
}
@media(max-width:1800px) {
    .hires-technology {
        top: 150px !important;
        right: -40% !important;
    }
    .aumnics-certificate .close { right: 13% !important;}
    .container { max-width: 90%;}
    .banner {height: 675px !important;}
    .banner-lft h1 {
        font-size: 34px !important;}
}
@media (min-width:1701px) {
    /* .background-opacity { top: -1800px !important}     */
    .package-card .package-banner-image{
        height: 240px;
    }
}

@media(max-width:1700px) {
    .aumnics-certificate .close { right: 10% !important;}
    .tabs-container .footer-buttons { width: 78% !important;}
    .hires-technology {
        right: -33% !important;
    }
    .about-us-rgt figure img { width: 100%; height: auto;}
    .about-us-rgt figure img, .about-us-banner.book-demo-banner .about-us-rgt figure img, .about-us-banner.contactus .about-us-rgt figure img { width:auto;}
    .mat-drawer {
        width: 78% !important;
    }
    .mat-drawer.full-width-drawer{
        width: 100% !important;
    }

        .banner-top {
            top: -27% !important; right: 20px !important;}
        .banner-top figure { width: 200px !important;}
        .video-block span {
            top: 0% !important;
            right: 0% !important;
        }
        /* .background-opacity { top: -1000px !important}     */

}




@media(min-device-width:1491px) {
    .company-purpose p{ margin-bottom: 47px !important;}
    .crossHireBlueArrow{  top: 28% !important;}
    .crossHireBlueArrow.horizontal{  bottom: 2% !important;}
    .developer_aumnics_left p.aumnics_para, .developer_aumnics_right p.aumnics_para { width: 95% !important;}
    .trees{ bottom: 0 !important;}
    .why-aumnics-logo-sec { margin-top: 10% !important;}
}
@media(max-device-width:1490px) {
    .company-purpose p{ margin-bottom: 0px !important;}
    .company-purpose .p-company-purpose {margin-bottom: 46px !important;}
    .company-purpose .company-purpose_content { padding-right: 0px !important;}
    .it_services_in br{display: none !important;}
    //.search-input .p-inputtext { min-width:  350px !important;}
}


@media only screen and (min-device-width: 1330px) and (max-device-width: 1510px) and (orientation:landscape) {
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-lft{
        height:93vh !important;
    }


    .aumnics-certificate .close {
        top: 10px !important; right: 40px !important;
    }
    .header-nav .navbar-dark .navbar-nav .active:after
    {
        bottom:-11px !important;
    }
    .container {
        max-width: 90%;}
    .banner-top {
        top: -28% !important; right:50px !important;
    }
    .banner-rgt figure img { width: 100%; height: auto;}
    
        .header-nav .navbar-dark .navbar-nav .nav-link{
            font-size: 14px !important;
        }
        .header-nav .navbar-nav.signup-last { margin-left: 30px !important;}
        .banner-lft p small{
            bottom: -8px !important;
            left: 0 !important;
            line-height: 0;
        }
        .banner-lft h1 { font-size:34px !important; line-height:48px !important;}
        .banner-lft h1 br { display: none;}
        .banner-rgt figure {
            width: 350px !important;
        }
        .banner-top figure {
            width: 160px !important;        
        }
        .banner-top figure img { width: 100%; height: auto;}
        .hires-technology {
            right: -5%!important;
            top:160px!important;
        }
        .software-tabs-lft .nav-pills .nav-link p br { display: none;}
        .hires-technology ul li a {font-size: 12px !important; padding:5px 8px !important;}
        .header-nav .nav-item {
            margin-right: 23px !important;
        }

        .video a {           
            padding: 8px 15px !important;
            width: 220px !important;          
        }
        .search-sec .btn {
            padding: 5px 15px !important;
        }
        .search-sec .form-control {padding: 0 20px !important;}
        .video h6 { font-size: 14px !important;}
        .banner-lft h2 { font-size: 15px !important; line-height: 25px !important;}
        .hires-technology ul li { margin-bottom: 12px !important;}
    .about-us-lft h2.sub-heading-one{ font-size: 15px !important; margin: 0 0 8px !important;}
    .reliability h2.heading-one{ font-size:28px !important;}
    .our-culture h2.heading-one { font-size: 30px !important;}
    .our-culture ul li h3.heading-three {
        font-size: 22px!important;        
  }
  .it_services_left p span { left:0 !important; right: auto !important;}
  .about-us-banner.pricing-banner .about-us-rgt figure img { width:inherit !important; height:inherit !important;}
  .pricing-main ul li .pricing-blog, .pricing-main ul li .pricing-blog:hover { min-height: 880px !important;}
  .most-questions h2, .journey-block h2.heading-one{
    line-height: 27px !important;    
    }

    .accordion .card h2 { font-size: 18px !important;}
    .lead-team ul li .team-section p.paragraph-global-text { min-height: 100px !important;}

    .book-demo-form .book-demo-lft .check-block p {width: 88% !important;}
    .software-services h2.heading-one { font-size:30px !important;}
    .software-services .software-services-in ul li button { font-size:14px !important;}
    .software-services .software-services-in ul li h3 { font-size: 20px !important;}
    .software-services .software-services-in ul li span { width: 50% !important;}

    .software-services .software-services-sec ul .arrow1.arrow5{
        top: -110px !important; right: 50px !important;
    }
    .hire-block h3.heading-two { font-size: 20px !important; line-height: 30px !important;}
    .hire-contractor .hire-cont {width: 70% !important;}
    .hire-block { padding: 0 20px !important;}
    .developer_aumnics_group .developer_aumnics_right figure img, .developer_aumnics_group .developer_aumnics_left figure img { width: 100%; height: auto;}
    .developer_aumnics_left p.aumnics_para, .developer_aumnics_right p.aumnics_para { width: 94% !important;}
    .business_aumnics p.paragraph-global-text span { right: auto !important; left: 0 !important;}
    .hiring_services_in h2{
        font-size: 25px!important;        
        line-height: 35px !important;
    }
    .over-layout .over-layout-header { padding: 20px !important;}
    .mat-drawer {
        width: 85% !important;
    }
    .mat-drawer.full-width-drawer{
        width: 100% !important;
    }
    .over-layout .over-layout-content {
        padding: 30px !important;
    }
    .course-container .course-tile .course-title h2 { font-size: 18px !important;}
    .video-block span { top: 5% !important; right: 5% !important;}
    .tabs-container .footer-buttons {
        width: 85% !important;
    }
    .join-us {
        padding: 14px 0 !important;
    }
        .course-container .course-tile { width: 23.5% !important;}
    .rgt-company-purpose .company_purpose-sub-img img { width: 216px !important;}
    .why-aumnics-in .logo img{ width: 90px !important; }
    .why-aumnics-in .logo_8 img { height:45px !important; }
    .why-aumnics-in .logo{ height: 110px !important; }
    .why-aumnics-logo-sec-right { height: 330px !important;}
    .trees{ bottom: 0 !important;}
}

@media(max-width:1381px) {
    .subscriber-head small{ left: 0px !important;}
}

@media(max-width:1300px) {
    //.search-input .p-inputtext { min-width:  260px !important; min-height: 60px !important;}
    .res-card {
        flex: 32.33%;
        max-width: 32.33%;
      }
    .about-positions ul li.our-story-card br{ display: none !important;}
    .subscriber-head small{ left: 100px !important;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-lft { width: 8% !important;}

    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-rgt figure { margin-left: -15px !important;}

    .aumnics-certificate .close {
        right: 5%!important;
    }
    .course-container .course-tile:nth-child(4n+4) {
        margin: 0 20px 20px 0 !important;
    }
    .course-container .course-tile:nth-child(3n+3) {
        margin: 0 0 20px !important;
    }
    .dev-main-card .profileLogo-140{
        min-width: 120px !important;
        min-height: 120px !important;
    }
    .dev-name { font-size: 28px !important;}
    .talent-scores{
        margin-left: 12px !important;
        max-width: 100px !important;
    }
    .banner-lft h1 {
        font-size: 34px !important;}
   .container { max-width: 95%;}
    .business_aumnics p.paragraph-global-text span { right: auto !important; left: 0 !important;}

    .about-us-banner.pricing-banner .about-us-rgt figure img { width:inherit !important; height:inherit !important;}
    .pricing-main ul li .pricing-blog, .pricing-main ul li .pricing-blog:hover { min-height: 880px !important;}
    .software-tabs-lft .nav-pills .nav-link p br{ display: none;}
   
        .hires-technology {
            right: 0% !important;
            top: 160px!important;
        }
        /* .banner-top figure {
            width: 200px !important;        
        } */
        .banner-top figure img { width: 100%; height: auto;}
      //  .header-nav .navbar-dark .navbar-nav .nav-link { padding:15px 10px !important;}
        .header-nav .navbar-nav.signup-last {
            margin-left: 30px !important;
}
.header-nav .navbar-nav .signup .nav-link ,.header-nav .navbar-nav .login .nav-link{
    padding: 14px 25px !important;
}
.header-nav .navbar-nav .book-demo .nav-link {
    padding: 11px 25px !important;
}
.header-nav .navbar-nav {margin-left: 20px !important;}
.lead-team ul li .team-section p.paragraph-global-text { padding: 0 10px !important;}
/* .book-demo-form { width: 60% !important;} */
.course-container .course-tile { width: 31.8% !important;}
.over-layout .over-layout-content {
    padding: 30px !important;
}
.course-container .course-tile .course-title h2 { font-size: 18px !important;}
.course-container .course-tile .course-title {
    min-height: 60px !important;
}
/* .join-subscriber-lft h4 { font-size: 22px !important;} */
.rgt-company-purpose .company_purpose-main-img {text-align: center;}
.rgt-company-purpose .company_purpose-sub-img img { width: 216px !important;}
.rgt-company-purpose .company_purpose-main-img img{ width: 90%;}
.news-aumincs-top a { padding: 12px 20px !important;} 
.container { max-width: 85%;}
.why-aumnics-in .logo img{ width: 80px !important; }
.why-aumnics-in .log_o8 img { height:50px !important; }
.why-aumnics-in .logo{ height: 100px !important; }
.why-aumnics-logo-sec-right { height: 301px !important;}
.it_services_left p span { left:0 !important; right: auto !important;}
.roles_aumnics_in p span { left: 0px !important;}
.news-aumincs-lft p small { left: 0 !important;}
.header-nav .nav-item{margin-right: 14px !important;}
.app-card{padding-right: 0px !important;}
    .crossHireBlueArrow{  top: 21% !important;}
    .crossHireBlueArrow.horizontal{  bottom: 16% !important;}
    .about-us-banner.aumnics-works figure{ margin-top: 100px !important;}
}

@media(max-width:1200px) {
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl {
        width: 70% !important;
        padding-right: 20px !important;
    }

    .cdk-overlay-pane.certificate-modal { max-width: 95vw !important;}
    .where-life-cycle-mobile .pay-block { padding: 13px 15px !important;}
    .course-container .course-tile {
        width: 31.4%!important;
    }
    .over-layout .over-layout-header .hearder-content .header-title h2 { font-size: 20px !important;}

    .over-layout .over-layout-header .hearder-content .search-box{
        width: 60% !important;
    }

    .software-services .software-services-sec ul li .software-cont {
        width: 90% !important;
    }
    .banner-lft h1 {
        font-size: 33px!important;
        line-height: 48px!important;
    }
    .banner-top {
        top: -35%!important;
        right: 0px!important;
    }

    .course-icons img {
        width: auto;
        height: 30px;
        object-fit: cover;
    }
    .business_aumnics_box{
        width: 175px !important;
    }
    .client_requirement_banner figure { width: 350px;}
    .client_requirement_banner figure img, .service_provider_banner figure img { width: 100%; height: auto;}
    .hiring_services_in{
        padding: 10px !important;
    }
    .hiring_aumnics_in h2.heading-one br { display: none;}
    .business_aumnics_box { height:400px !important;}
    .developer_aumnics_group { margin-bottom: 50px;}
    .aumnics_benifits_category { padding: 30px 30px 0 !important;}
    .developer_aumnics_group .developer_aumnics_right figure img, .developer_aumnics_group .developer_aumnics_left figure img { width: 100%; height: auto;}
    .it_services_left p span { left:0 !important; right: auto !important;}
    .about-us-banner.book-demo-banner .about-us-lft small { right: auto !important;}
    .support-main .support-in .support-lft .support-search { width: 85% !important;}
    .support-main .support-in .support-lft .support-search .search { width: 73% !important;}

    .pricing-main ul li .pricing-blog h3 {  font-size: 25px !important; line-height: 25px !important;}

    .reliability ul li .reliability-cont h5 { padding: 0 50px 0 0 !important;}

    footer ul li .footer-cont ul li p { padding-right: 0 !important;}

    .news-aumincs-lft p small {
        left: 0 !important;
        right: auto !important;
    }

    .news-aumincs-lft { width: 70%;}
    .our-culture ul li {
        padding: 0 30px 0 0 !important;
    }
    .lead-team ul li .team-section p.paragraph-global-text {
        padding: 0 !important;min-height:100px !important;
    }
    .software-needs-lft { width: 70%;}
    .journey-block ul:after {
        top: 30px !important; width: 90% !important;
    }
    .pricing-main ul li .pricing-blog { padding: 80px 10px 10px !important;}
   // .header-nav .navbar-dark .navbar-nav .nav-link { padding: 15px 10px !important;}
    .header-nav .navbar-nav .signup .nav-link,.header-nav .navbar-nav .login .nav-link { padding: 13px 25px !important;}
    .header-nav .navbar-nav .book-demo .nav-link { padding: 10px 25px !important;}
   
    .banner-rgt figure img { width: 100%; height: auto;}
    .banner-lft h1 br { display: none;}
    .header-nav .navbar-dark .navbar-nav .nav-link{
         font-size: 14px !important;
    }
    .search-sec .form-control { padding: 0 20px !important;}
    .banner-lft p small {
        bottom: -15px !important;
        left: 0 !important;
    }
    .logo a { width:150px; display: block;}
    .logo a img { width: 100%; height: auto;}
    .banner-rgt .banner-top figure { width: 180px !important;}
    .hires-technology ul li a { font-size: 13px !important;}
    .software-tabs-cont figure { width: 85% !important;}
    .header-nav .navbar-nav.signup-last { margin-left: 20px !important;}
    .header-nav .nav-item {
        margin-right: 15px !important;
    }
    header.new-header-style{ padding: 13px 0 !important;}
    .banner-top figure {
        width: 200px!important;
    }
    .about-positions ul li.our-story-space { padding-left: 30px !important;}
    .about-positions ul li.our-story-space-lft {padding-left: 0px !important;}
    .location .location-in .location-tabs-lft .nav-pills .nav-link address p { width: 65% !important;}
    /* .book-demo-form {
        width: 70%!important;
    } */
    .about-us-banner.aumnics-works .about-us-rgt p.paragraph-global-text { width: 60% !important;}
    .software-services .software-services-sec, .software-services .software-services-in { width: 90% !important;}
    .hire-block{padding-left: 20px;}
    .payment-block h4 span { width: 80% !important;}
    .software-services .software-services-sec .soft-service {
        top: -110px !important;
    }
    .why-aumnics-in .logo img{ width: 70px !important; }
    .why-aumnics-in .logo_8 img { height:45px !important; }
    .why-aumnics-in .logo{width: 50% !important; height: 90px !important; }
    .why-aumnics-logo-sec-right { height: 180px !important;}
    .why-aumnics-in .bg-div{ min-height: 200px !important;}
    /* .why-aumnics-in .logo_5 ,.why-aumnics-in .logo_6,.why-aumnics-in .logo_7,.why-aumnics-in .logo_8,.why-aumnics-in .logo_9
     { display: none !important;} */
     .software-needs-lft span { left: 0 !important;}
     .roles_aumnics_in p span { left: 65px !important;}
     .about-positions ul li small { left: 0 !important;}
     .team-lft p small{ left: 0px !important;}
     .careers-aumnics p small { right: auto!important;}
     .quate-div{padding: 30px 50px !important;  }
     .quate-div .opening-quote{top: -28px !important; left: 14px !important; max-width: 90px !important;}
     .quate-div .closing-quote{bottom: -28px !important; right: 14px !important; max-width: 90px !important;}
     .software_services_in .opening-quote{ top: 28px !important;}
     .software_services_in .closing-quote{ bottom: 28px !important}
     .connecting_software_arrow{ max-width: 80px !important;}
     .why-aumnics-in .description-sub-sec-rgt br{ display: none !important; }
     .thumbsup-animation{right: 20% !important; top: 35% !important; }
}

@media(max-width:1132px) {
    .subscriber-head small{ left: 0px !important;}
    .cms-banner-img-sec figure img { width: 80% !important;}
}
@media(max-width:1100px) {
    
    .header-nav .navbar-dark .navbar-nav .nav-link{
        font-size: 12px !important;
   }
   .search-input .p-inputtext { font-size: 17px !important; }
   .crossHireBlueArrow{  top: 15% !important;}
    .crossHireBlueArrow.horizontal{  bottom: 38% !important;}
    .company-content .menu-item{width: 100% !important;}
}

@media(max-width:992px) {

    .sticky-header-banner{ margin-top: -67px !important;}
    header.new-header-style{ padding: 10px 0 !important;}
    .listing-search.mobile-search { margin-bottom: 25px!important}
    .list-content-sec { padding: 0 !important;}
    .listing-sec-head { padding: 40px 10px  !important;}
    .collab-home-sec .img-sec { width: 100% !important; position: inherit !important;}
    .collab-div .content-sec, .collab-div .img-sec {
        width: 100% !important;
        padding: 20px !important;
    }
    .res-card {
        flex: 49%;
        max-width: 49%;
      }
    .developer-card { padding: 20px !important;}
    .quate-div { font-size: 20px !important;}
    .quate-div .opening-quote{top: -20px !important; left: 14px !important; max-width: 75px !important;}
     .quate-div .closing-quote{bottom: -20px !important; right: 14px !important; max-width: 75px !important;}
     .software_services_in .opening-quote{ top: 28px !important;}
     .software_services_in .closing-quote{ bottom: 28px !important}
    .map-wave-rwa{ top: 18% !important; }
    .map-wave-rca{ top: 26% !important;  }
    .map-wave-rny{ top: 19% !important;  }
    .map-wave-rtx{ top: 28.5% !important; }
    .crossHireBlueArrow{  top: 13% !important;}
    .crossHireBlueArrow.horizontal{  bottom: 50% !important;}
    .crossHireBlueArrow div{ width: 50px !important;
    }

    .dev-main-card .profileLogo-140{
        min-width: 80px !important;
        min-height: 80px !important;
    }
    .dev-name { font-size: 22px !important;}

    .talent-scores{
        margin-left: -6px !important;
    }
    .talent-scores h2{ font-size: 16px !important}
    
    .profile-sub-card { flex-direction: column !important;}
    .profile-lft-sec { width: 100% !important;}
    .profile-lft-sec .common-dev-card-div { margin-right: 0 !important;}
    .profile-rgt-sec { width: 100% !important;}

    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-lft { width: 100% !important;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-rgt figure {
        margin-left: 0px !important;
    }
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-btm ul {flex-wrap: wrap; align-items: flex-start !important;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-btm ul li {width: 50%; margin-bottom: 20px;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-btm ul li.last-border{ border-left:none !important; padding-left: 0 !important;}

    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-rgt{
        width: 32% !important;
    } 
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl {
        width: 60% !important;
        padding-right: 0 !important;
    }

    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-lft { display: none;}
    .categories-sec .categories-in .categories-rgt {
        width: 65% !important;
    }
    .software-services .software-services-sec ul .service-arrow {       
        transform: rotate(-55deg);
    }

    .tabs-container .footer-buttons {
        width: 90% !important;
    }
    .where-life-cycle-mobile { flex-wrap: wrap;}
    .group-addon.group-addon1.minimum-exp-mobile .p-dropdown{
        width: 80px !important;
    }
    .book-slot-section .book-slot-section-in {padding: 30px 0 !important;}
    .slotOptsDiv { width: 100% !important;}
    .cookies-section .cookies-lft {       
        width: 60% !important;
    }
    .cookies-section .cookies-rgt .privacy{ margin-left: 10px !important;}

    .course-container .course-tile:nth-child(3n+3) {
        margin: 0 20px 20px 0!important;
    }
    .course-container .course-tile:nth-child(2n+2) {
        margin: 0 0 20px!important;
    }
    .mat-drawer {
        width: 90% !important;
    }
    .mat-drawer.full-width-drawer{
        width: 100% !important;
    }
    .over-layout .over-layout-header { padding: 15px 20px !important;}
    .over-layout .over-layout-header .hearder-content .search-box .mat-icon { font-size: 30px !important;}

    .over-layout .over-layout-header .hearder-content .header-title img { width: 40px; height: auto;}
    .over-layout .over-layout-header .hearder-content .search-box .search-sec{
         margin-right: 10px !important;
    }

    .over-layout .over-layout-header .hearder-content .search-box {
        width: 50%!important;
    }
    .header-nav .navbar { width: 87% !important;}
    .book-demo-form.mobile-contact { width:90% !important; margin: auto;}
    .book-demo-form.mobile-contact ul, .book-demo-form.mobile-contact ul.contact-sub { width:85% !important;}

    .book-demo-rgt { padding: 0 10px 0 0 !important;}
    .course-icons img {
        height: 40px !important;
    }
    .course-container .course-tile {
        width: 48% !important;
    }
    .about-us-banner.aumnics-works .about-us-rgt p.paragraph-global-text {
        width: 80%!important;
    }
    .book-demo-form .book-demo-rgt .book-demo-cont { padding: 10px 10px 0 !important;}

    .book-demo-form .book-demo-lft .radio-check li:nth-child(1) {
        width: 100% !important;
    }
    .book-demo-form .book-demo-lft .radio-check { flex-wrap: wrap;}
    .book-demo-form { width: 100% !important;}
    .about-us-banner.book-demo-banner .about-us-rgt figure img { width: 100% !important;}
    .categories ul li h4 { min-height: 70px !important;}
    .support-main .support-in .support-lft .support-search .search {
        width: 70% !important;
    }
    .support-main .support-in .support-lft .support-search {
        width: 100%!important;
    }
    .categories { margin-top: -40px !important;}
    .pricing-main ul li .pricing-blog a {padding: 8px 10px !important; font-size: 14px !important;}

    footer ul li .footer-cont ul li  p { padding-right: 20px !important;}
    .pricing-main ul li .pricing-blog, .pricing-main ul li .pricing-blog:hover {
        min-height: 1060px!important;
    }
    .pricing-main ul li .pricing-blog {
        padding: 50px 10px 10px!important;
    }
    .pricing-main ul li .pricing-blog h3 {
        font-size: 25px !important;
    }
    .banner-rgt figure img, .support-in .support-rgt figure img {width: 100%; height: auto;}
    .hires-technology {
        top: 170px !important;
    }
    .banner-rgt figure {
        width: 350px !important;
    }
    /* .join-subscriber-lft h4 {
        font-size: 18px!important;
    } */
    .logo a {
        width: 120px !important;
    }
    .header-nav .nav-item {
        margin-right: 10px!important;
    }
    .header-nav .navbar-dark .navbar-nav .nav-link { font-size:12px !important; line-height:14px !important;}
    .header-nav .navbar-nav.signup-last {
        margin-left: 10px !important;
    }
    .header-nav .nav-item:nth-last-child(1) { margin-right: 0 !important;}
    .header-nav .navbar-nav .signup .nav-link,.header-nav .navbar-nav .login .nav-link { padding: 13px 25px !important;}
    .header-nav .navbar-nav .book-demo .nav-link { padding: 10px 15px !important;}
    .header-nav .navbar-nav {margin-left: 0px !important;}
    .banner-lft h1{
        font-size: 34px !important;
        line-height: 40px !important;
    }
    .hires-technology ul li a {
        font-size: 13px!important;
    }
    .search-sec { width: 100% !important;}
    .search-sec .btn{
        padding: 0px 7px !important; font-size: 15px !important; line-height: 20px !important;
    }
    .search-sec .form-control { font-size: 17px !important; line-height: 22px !important; padding: 0 10px !important;}
    .payment-block h4, .payment-block-right { padding: 0 !important;}
    .payment-block {padding: 30px 20px !important;}
    .meeting button { padding: 12px 15px !important;}
    .software-needs-lft { width: 70% !important;}
    .software-tabs-cont figure {
        width: 100%!important;
    }
    .software-needs-lft p.paragraph-global-text br { display:none;}
    .banner-top {
        top: -36%! important; right:0 !important;
    }
    .collaboration span { bottom: -10px !important; right: auto !important;}
    .integration-apps ul li h4.heading-three { font-size: 20px !important ; min-height: 56px;}
    .integration-apps ul li p.paragraph-global-subtest { min-height: 130px !important;}
    .collaboration-in figure { width: 60%;}
    .collaboration-in figure img { width: 100%; height: auto;}
    .collaboration-right {   width: 38% !important;}
    .join-subscriber-rgt .mat-error { margin-left: 0% !important;}
    .join-subscriber-lft { padding: 0 !important;}
    .subscriber-head {
        padding: 0 20px !important;
    }
    .subscriber-head small{ left: 0px !important;}
    // .join-subscriber-rgt { max-width: 45% !important;}
    .join-subscriber-rgt .join-subscriber-rgt-in { max-width: 100% !important;}
    .join-subscriber-rgt .form-control {
        padding: 12px 10px 11px  !important; font-size: 14px !important;
    }
    .join-subscriber-rgt-in button{ padding: 11px !important;}
    footer ul li { padding: 0 !important;}
    footer ul li .footer-cont ul li h2.heading-two {
        font-size: 20px !important;
    }
    .copy_in {
        justify-content: space-between !important;
        flex-wrap: wrap;
    }
    .social-icons { margin-bottom: 0;}
    .collaboration-right h5 {
            font-size: 22px !important; line-height: 35px !important;
    }
    .subscriber-head small { bottom: 0 !important;}
    footer ul li .footer-cont ul .referal-program { padding: 20px 0 0 !important;}
    .banner-lft h2{
        font-size: 15px !important;
        line-height: 25px !important;
    }
    .banner-rgt .banner-top figure {
        width: 180px !important;
    }
    .join-us, .resource { display: none;}

    .about-us-lft h1.heading-one { font-size: 27px !important; line-height: 35px !important;}
    .about-us-lft p br { display: none;}
    .about-us-lft small { left:0; right:auto !important;}

    .about-positions ul li h2.heading-one{
        font-size: 28px!important;
        line-height: 35px!important;padding: 0 50px 0 0 !important;
    }
    .reliability ul li .reliability-cont { width: 90% !important;}
    .reliability ul:after { left: -30px !important;}
    .team-lft { width: 80%;}
    .team-top a {

        padding: 12px 20px !important;
        font-size: 14px !important;

}
.lead-team ul li .team-section p.paragraph-global-text {
    min-height: 150px !important;
}
.our-culture p br, .careers-aumnics p br { display:none;}
.careers-aumnics h2.heading-one { font-size: 28px !important;}
.team-lft p small, .our-culture p small { left:0 !important; right: auto !important;}
.reliability h2.heading-one { font-size: 30px !important;}

.careers-aumnics p small { right: auto !important;}
.reliability ul li .reliability-cont ul li p {
    padding: 8px 60px 8px 20px !important;
}
.reliability ul li .reliability-cont h5 {
    padding: 0!important;
}
.our-culture ul li {
    padding: 0 15px 0 0!important;
}

.news-aumincs li .news-cont h3 br { display: none;}
.about-us-banner.contactus .about-us-rgt figure img { width: 100% !important;}
/* .book-demo-form {
    width: 80%!important;
} */
.book-demo-form ul, .book-demo-form ul.contact-sub { width: 100%; margin: auto;}
/* .book-demo-form button { margin-left: 30px;} */
.location .location-in .location-tabs-lft .nav-pills .nav-link address p {
    width: 100% !important;
}
.location .location-in .location-tabs-lft { margin-bottom: 45px;}
.location .location-in {
    margin: 50px 0 !important;
}
.about-us-banner.aumnics-works .about-us-lft small {left: 27% !important;}
.software-services .software-services-sec, .software-services .software-services-in {
    width: 100%!important;
}
.software-services .software-services-in ul li button { padding: 10px !important; width: 90% !important;}

.software-services .software-services-sec ul .arrow1 { top: -110px !important;}
.hire-contractor { padding: 20px !important;}
.hire-contractor button { padding: 15px !important;}
.software-services .software-services-in ul li span { width: 70% !important;}
.it_services_right figure img {width: 100%; height: auto;}

.business_aumnics p.paragraph-global-text,.business_aumnics h2.heading-one { width: auto !important;}
.business_aumnics_in {
    flex-wrap: wrap; justify-content: center;
}
.business_aumnics_sub, .business_aumnics_box { width: 32% !important; margin: 0 0 25px;}
.aumnics_benifits_category {
    padding: 30px 10px 0!important;
}
.company_aumnics_tabs .nav-pills .nav-link { text-align: left; padding: 10px 20px !important;}
.company_aumnics_tabs .nav { align-items:flex-start;}
.company_aumnics_tabs { padding: 0 0 0 10px !important;}
.hiring_services_in h2 {
    font-size: 20px!important;
    margin-right: 0 !important;
    line-height: 30px !important;
}
.company_aumnics_data {
    padding: 50px 0 30px 0 !important;}

    .employee_requirement_left p span {right: auto !important;}
    .service_provider_in h2.heading-one { font-size: 25px !important; line-height:35px !important;}
    .aumnics_benifits_in p span { left: 0 !important;}
    .employee_requirement_left {
        padding-right: 30px !important;
    }

    .hiring_services { padding:0 !important;}
    .employee_requirement_left h2 { margin-right: 0 !important;}

    .contactor-form .formgrid.flex-nowrap {
        flex-wrap: wrap !important;
    }
    .why-aumnics-in .logo img{
        width: 65px !important;
    }
    .logo_8 img{height: 45px !important;}
    .company-purpose .rgt-company-purpose{display: none !important;}
    .company-purpose .lft-company-purpose{ max-width: 100% !important;}
    .integration-apps span { left: 0 !important;}
    .hiring_aumnics_in p span { left: 0 !important;}
    .banner-bg-div { width: 50% !important;}
    .banner-sec-it { top: 76px !important;}
    .banner-bg-div .banner-heading { font-size: 18px !important;}
    .banner-bg-div .banner-dy-heading { font-size:32px !important}
    .map-arrow-it { top: 28% !important;}
    .company_aumnics_content { flex-direction: column !important;}
    .company_aumnics_content figure img {margin-bottom: 20px !important; margin-right: 0px !important;}
    .contact-numbers-div {  flex-direction: column;  }
    .contact-numbers-div li { margin-bottom: 15px !important;}
    .contact-numbers-div li span{ margin-bottom: 10px !important;}
    .hide-on-tab { display: none !important}
    .show-on-tab { display: block !important;}
    .mobile-search {  display: flex !important; }
    .nav-scroll { padding: 0px 0px 15px !important;}
    .listing-package-sec.listing-sec-head::after {
        width: 235px !important;
    }
    .search-input .p-autocomplete {
        max-width: 100% !important;
    }
    .search-input .p-inputtext { 
        max-width: 100% !important;
    }
    .search-input.p-inputwrapper-focus .p-autocomplete,.search-input.p-inputwrapper-focus .p-inputtext {
        max-width: 400px !important;
    }
    .app-integrations-padding-inner,.why-choose-gradient-card .dots-background { padding: 40px 20px !important;}
    .nav-bar-padding { padding-left: 20px !important; padding-right: 20px !important; }
}

@media(max-width:780px) {
    .about-us-banner-img figure{ text-align: center !important;   }
    .quote-sec { padding: 0 !important;}    
    .home-banner br {
        display: none;
    }
    .search-sec .btn {
        padding: 5px 15px!important;                    
    }
    .aumnics_resources_in p span { right: auto !important;}
   .map-wave-l{ top: 26.1% !important;}
   .it_services_in{ flex-direction: column;}
   .it_services_in .it_services_left , .it_services_in .it_services_right{ max-width: 100% !important;}
   .it_services_in .it_services_right { margin-top: 30px !important;}
   .listing-search.mobile-search { margin-bottom: 25px!important}
}

@media (min-device-width: 765px) {
    .nav-item .dropdown-content{ display: none !important;}
    .w-active-nav .nav-link{ color: #4B47F1 !important;}
     .w-active-nav .nav-link .fa-angle-down{transform: rotate(180deg);}
     .subscriptions-div{
        margin-top: 45px;
     }
     .about-affiliate-cards{
        display: flex !important;
    }
    .careers-aumnics .col-div { padding: 0 10px !important}
}
@media(max-width:767px) {
    .m-flex-column { display: flex; flex-direction: column !important;}
    .m-flex-column-reverce { display: flex; flex-direction: column-reverse !important}
    .m-mb { margin-bottom: 40px !important;}
   
    .cms-banner-img-sec { position: inherit !important;}
    .cms-banner-img-sec figure img { width: 100% !important;}
   
    .mobile-search { margin-bottom: -5px !important;}
    .listing-search.mobile-search { margin-bottom: 25px!important}
    .listing-package-sec.listing-sec-head::after { display: none !important;}
    .software-banner-sec .image-sec img{ position: static !important; margin-bottom: -10%;}
    .software-banner-sec .image-sec .y-g-image{ display: none !important; }
    .mobile-flex-column { flex-direction: column;}
    .mobile-flex-column-reverse { flex-direction: column-reverse;}
    .m-mb { margin-bottom: 40px !important;}
    .common-dev-card-div{ padding: 20px !important;}
    .dev-main-card .dev-res-contet{ flex-direction: column-reverse !important;}
    .dev-main-card .save-btn, .dev-main-card .cancel-btn {
         padding: 0 10px !important;
         min-width: 50px !important;
         min-height: 25px !important;
         font-size: 10px !important;
        }
    .dev-main-card .save-btn { margin-right: 10px !important;}
    .res-card {
        flex: 100%;
        max-width: 100%;
        margin: 3% 0 !important;
      }
    .mobile-countries{
        display: block !important;
    }
    .web-countries{
        display: none !important;
    }
    .nav-flilter-text {
        display: none !important;
    }
    .map-wave-rwa{ top: 17% !important;  }
    .map-wave-rca{ top: 25% !important;  }
    .map-wave-rny{ top: 18% !important;   }
    .map-wave-rtx{ top: 27.5% !important;}
    .crossHireBlueArrow{  top: 29% !important;}
    .crossHireBlueArrow.horizontal{  bottom: 0% !important;}
    /* .background-opacity{top: 0 !important} */
    .software_services_in .container { flex-direction: column;}
    .service_provider_button { font-size: 15px !important;}
    .company-aumincs .company_aumnics_content p.paragraph-global-text { margin: 0 !important;}
    .company_aumnics_verification { display: none !important;}
    .company_aumnics .mat-expansion-panel-header{ height: 96px !important;}
    .company-aumincs {
        margin: 40px 0 0 !important; display:block !important; padding: 10px 0 0 !important;
      }
    .heading_business_aumnics {padding-top: 50px !important;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-rgt figure{ margin:auto !important;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-name { padding: 20px 0 !important; margin: 20px 0 !important;}

    .aumnics-certificate .aumnics-certificate-in .certificate-cont { flex-wrap: wrap; padding: 10px 20px 20px !important;}

    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl { width: 100% !important;}
    .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-rgt { width: 100% !important;}

    .cc-nb-main-container { display: none !important;}
    .categories-sec .categories-in {
         flex-wrap: wrap;
    }
    .categories-sec .categories-in .categories-lft, .categories-sec .categories-in .categories-rgt {
        width: 100% !important;
    }

    .where-life-cycle-mobile .pay-block { width: 43% !important;}
    .enter-email-padding {
        max-width: 100% !important;
        width: 62% !important;
    }

    .min-exp-below-mobile{
        flex-wrap: wrap;
    }
    .min-exp-below-mobile .field.field1 { margin-bottom: 0 !important;}
    .banner-rgt { display: none;}

    .book-slot-section-in { flex-wrap: wrap; text-align: center;}
    .book-slot-lft { width: 100% !important;}
    .book-slot-rgt { width: 100% !important;}
    .book-slot-section p small { left: 0 !important;}
    .cookies-section { padding: 20px !important;}
    .cookies-section .cookies-rgt .privacy {
        margin-left: 5px !important;
    }
    .cookies-section .cookies-lft p {
         padding-left: 20px !important; font-size: 16px !important;
    }
    .cookies-section .cookies-rgt .accept, .cookies-section .cookies-rgt .privacy{
        padding: 10px 17px !important;
    }

    .cookies-section .cookies-rgt .privacy{ margin-left: 10px !important;}

    .course-container .course-tile:nth-child(3n+3) {
        margin: 0 10px 10px 0!important;
    }
    .course-container .course-tile:nth-child(2n+2){
        margin: 0 0 10px !important;
    }
    .book-demo-form button {
        margin-left: 0 !important;
    }
    .book-demo-rgt {
        padding: 0 10px!important;
        width: 100%;
        margin: auto;
    }

    .about-us-rgt figure {
        margin: auto;
    }

    .book-demo-form.mobile-contact {
       width: 90% !important;
    }
    .about-us-banner.contactus .about-us-rgt figure img {
        width:auto !important;
    }

    .book-demo-lft { margin-bottom: 40px;}
    .book-demo-form { flex-wrap: wrap;}
    #bookADemoForm .showMore-btn { margin-top: 40px !important;}
    .about-us-banner.book-demo-banner .about-us-rgt figure{
        text-align: center !important;
    }
    /* .about-us-banner.book-demo-banner .about-us-rgt figure img {
        width: auto !important;
    } */
    .search-sec .form-control .d-flex { display: none !important;}
    .most-questions, .support-btm{
        margin-bottom: 50px !important;
    }
    .categories ul li { width: 48% !important; padding: 15px !important;}
    .categories {
        margin-top: -50px!important; padding: 30px 20px 30px !important; margin-bottom: 50px !important;
    }
    .support-in .support-lft { margin-bottom: 30px;}
    .support-in .support-rgt figure { width: 300px; margin: auto;}
    .support-main .support-in { flex-wrap: wrap;}
    .about-positions ul li small { left: 0 !important;}

    footer ul li .footer-cont ul li p {
        padding-right: 0!important;
    }
    .accordion .card h2 { font-size: 17px !important; margin: 0 !important;}
    .most-questions .view-all span { width: 35% !important;}
    .journey-block h2.heading-one, .most-questions h2 { font-size: 28px !important; line-height: 35px !important; margin: 0 0 30px !important;}
    .most-questions h2 { font-size: 28px !important; line-height: 35px;}
    .lets-talk { margin: 0 0 50px !important; flex-wrap: wrap;}
    .lets-talk h3 { margin:0 0 15px !important;}
    .lets-talk button { margin: auto;}
    .pricing-main ul li .pricing-blog, .pricing-main ul li .pricing-blog:hover {
        min-height: 770px!important;
    }
    .pricing-main ul { flex-wrap: wrap;}
    .pricing-main ul li {width: 48% !important; margin-bottom: 40px;}
    .about-us-rgt.text-right { text-align: center !important;}
    .banner-rgt figure {
        width: 280px !important; margin-left: auto !important;
    }
    .join-subscriber-rgt .join-subscriber-rgt-in { margin-bottom: 10px;}
    /* .hires-technology { display: none;} */
    .hires-technology ul li a { font-size: 12px !important; padding: 5px 10px !important;}
    .hires-technology ul li { margin-bottom: 15px !important;}
    .banner-rgt figure img { width: 100%; height: auto;}
    .banner { height:650px !important;}
    .banner-top {
        top: -130px !important;
    }
    .banner-lft h1 {
        font-size: 30px!important;
        line-height: 40px!important;
    }
    .banner-lft h2{
        letter-spacing: 0.56px !important;
        line-height: 25px !important;
    }
    .banner-lft p  small { left: 0 !important;} 
    .video a {width: 210px !important;}
    .video h6 { font-size: 12px !important;}
    .video .mat-icon { font-size: 24px !important;margin-bottom: none !important;}
    .company-purpose .rgt-company-purpose{display: none !important;}
    .company-purpose .lft-company-purpose{ max-width: 100% !important;}
    .payment-block { flex-wrap: wrap;}
    .collaboration-in { flex-wrap: wrap;}
    .collaboration-in figure { width: auto;}
    .collaboration-in img{ width: 100% !important;}

    .container-nav{display: flex;justify-content: space-between;  }
    
    .collaboration-right {
        width: 100%!important; padding: 30px 20px !important;
    }
    .collaboration-in figure {
        margin-bottom: 40px;
    }
    .integration-apps ul { flex-wrap: wrap;}
    .integration-apps ul li { width: 48% !important; padding-right: 0 !important; margin-bottom: 40px !important;}
    .integration-apps ul li h4.heading-three {
        font-size: 18px!important;
        min-height: auto !important;
    }
    .integration-apps ul li p.paragraph-global-subtest { margin: 0 !important;}
    .software-needs {
        padding: 30px 0 40px !important;
    }
    .collaboration-in { margin-top: 40px !important;}
    .integration-apps {padding: 20px 0 !important;}
    .join-subscriber-in { flex-wrap: wrap; justify-content: center !important; padding: 30px 0 20px !important;}
    .join-subscriber-rgt {
        max-width:inherit !important; padding: 0 10px !important; width: 420px !important;
    }
    .join-subscriber-lft { margin-bottom: 30px;}
    footer ul { flex-wrap: wrap;}
    footer ul li { width: 50% !important; padding: 0 5px 0 0!important; margin-bottom: 15px;}
    .subscriber-head small { bottom: 0 !important;}
    .software-tabs-lft .nav-pills .nav-link p br { display: none;}
    footer ul li .footer-cont ul li { width: 100% !important;}
    footer {
        padding: 30px 0 10px !important;
    }
    footer ul li .footer-cont ul .referal-program { padding: 15px 0 0 !important;}
    .copy-sec ul { flex-wrap: wrap; justify-content: center !important;}
    .copy-sec ul li { margin: 0 10px 10px !important;}
    .copy {
        padding: 0px 0 10px !important;
    }
    .social-icons { margin-bottom: 15px;}
    /* .payment-block h4 { margin-bottom:10px !important;} */
    .banner-rgt figure {
        width: 250px !important;
        margin: auto !important;
    }
    .banner-top figure {
        width: 200px !important;
    }
    

    .about-us-cont { flex-wrap: wrap;}
    .about-positions ul li h2 br { display: none;}
    .about-positions ul li { margin-bottom: 40px;}
    .about-positions ul { margin-bottom: 20px !important;}
    .about-positions ul li.our-story-space {
        padding-left: 0 !important;
    }
    .reliability ul { flex-wrap:wrap;}
    .reliability ul::after { display: none;}
    .reliability ul li { margin-bottom: 40px;}
    .reliability ul li .reliability-cont {
        width: 100%!important;
    }
    .team-top { flex-wrap: wrap;}
    .team-lft {
        width: 100% !important; margin-bottom: 30px;
    }
    .lead-team ul li {
        width: 46% !important; padding: 0 !important;
    }
    .lead-team ul { flex-wrap: wrap;}
    .reliability ul li .reliability-cont ul li { margin-bottom: 0 !important;}
    .reliability {
        padding: 70px 0 30px !important;
    }
    .lead-team ul li { margin-bottom: 30px;}
    .careers-block { padding: 30px !important; flex-wrap: wrap;}
    .careers-block-left { margin-bottom:25px;}
    .careers-block-left h3.heading-one { font-size: 28px !important; margin: 0 0 10px !important;}
    .news-aumincs-lft p.paragraph-global-text br { display: none;}
    .news-aumincs-top { flex-wrap: wrap;}
    .news-aumincs-lft { margin-bottom: 20px;}
    .our-culture ul li h3 br { display: none;}
    .news-aumincs-lft p small { left: 0 !important;}
    .careers-aumnics p small { right:auto !important;}

    .about-us-lft {
        padding: 0 0 30px;
    }

    .news-aumincs li .news-section { flex-wrap: wrap;}
    .news-aumincs li .news-cont { padding: 30px 0 0 !important;}

    .about-positions ul li p br { display: none;}
    .lets-talk {    padding: 30px !important; justify-content: center !important;}
    .journey-block ul li img { width: 100%; height: auto;}
    .journey-block ul li h3 { font-size: 14px !important; line-height: 23px !important;}
    .journey-block ul:after {
        top: 10px!important;
    }
    .software-services .software-services-in ul li h3 {
        font-size: 16px !important;
    }
    .software-services .software-services-in ul li button {
        padding: 5px 5px !important;
        width: 95%!important;
    }
    .software-services .software-services-in ul li span { width: 80% !important;}
    .software-services .software-services-in ul { align-items: flex-start !important;}
    .software-services p small { left: 0 !important;}
    .software-services .software-services-sec small {
        top: -110px !important;
        left: 0 !important;
        width: 60px !important;
        margin: auto; right: 0;
    }
    .software-services .software-services-sec ul li .software-cont{
        padding: 20px 15px !important; width: 100% !important;
    }
    .software-services .software-services-sec ul li .software-cont h3{
        font-size: 20px !important;
    }
    .hire-contractor {
        flex-wrap: wrap; justify-content: center !important;
    }
    .hire-contractor .hire-cont { flex-wrap: wrap; justify-content: center !important; width: 100% !important;}
    .hire-contractor .hire-cont span { width: auto !important;}
    .hire-block { width: 100% !important; text-align: center; margin: 20px 0;}

    .software-services .software-services-sec ul .service-arrow {
        top: -90px !important;
    }
    .hire-block { padding: 0 !important;}
    .developer_aumnics_in p.paragraph-global-text span { left: 0 !important;}
    .developer_aumnics_group { flex-wrap: wrap;}
    .developer_aumnics_left p.aumnics_para, .developer_aumnics_right p.aumnics_para{ width: auto !important;}
    .developer_aumnics_right { padding: 0;}
    .view-all span { width: 50% !important;}
    .business_aumnics_sub {
        width: 48% !important;
    }
    .business_aumnics_box {width: 48% !important;}
    .business_aumnics_box:nth-child(even){ margin-right: 0 !important;}
    .business_aumnics_main { flex-wrap: wrap; justify-content: space-between;}
    .aumnics_resources_in p span { right: auto !important;}
    .aumnics_benifits_category { width: 48% !important;}
    .software_services_in{ flex-wrap: wrap;}
    .software_services_but {
        text-align: center !important; margin-top: 20px;
    }
    .hiring_aumnics_in p span { left: 0 !important;}
    .hiring_services{ flex-wrap: wrap;}
    .hiring_services_in { width: 47% !important; margin-bottom: 40px; padding: 10px !important;}
    .hiring_services_in::after:nth-child(2n+1) { border-right: transparent !important;}
    .client_requirement_in { flex-wrap: wrap;}
    .employee_requirement_left{ width: 100% !important;}
    .employee_requirement_left h2 {
        margin-right: 0 !important;
    }
    .employee_requirement_right { padding: 30px 0 0 !important; width: 100% !important;}
    .user-access .bread-crums { flex-wrap: wrap;}
    .user-access .bread-crums ul { margin-bottom: 30px;}
    .user-access .bread-crums ul li a {
    font-size: 16px;}
    .user-access .bread-crums .icons li {
        margin-left: 0 !important; margin-right: 20px;
    }
    .security-block .security-block-in { flex-wrap: wrap;}
    .security-block .security-block-in .security-block-lft { margin-bottom: 30px;}
    .security-block .security-block-in .security-block-rgt {
        max-width: 100% !important;
        padding: 0 !important;
    }
    .user-access .bread-crums ul.icons{ margin-bottom: 0 !important;}
    .payment-block h4 span { width: 100% !important;}
    .it_services_in {
        flex-wrap: wrap;
    }
    .it_services_in .it_services_left { margin-bottom: 30px;}
    .it_services_in .it_services_right figure { width: 300px; text-align: center !important; margin: auto;}

    .developer_aumnics_group.mobile-view-sections {
        flex-direction:column;
    }

    .developer_aumnics_group{
        flex-wrap: wrap-reverse !important;
    }
    .company_aumnics_in h2.heading-one { font-size: 28px !important;}
    .company_aumnics_data {
        width: 100%;
        flex-wrap: wrap; padding: 30px 0 !important;
    }
    .company_aumnics_tabs {
        max-width: 100% !important;
        flex: 100% !important; margin-bottom: 20px;
    }
    .company_aumnics_content { max-width: 100% !important; flex: 100% !important;}

    .company_aumnics_tabs .nav-pills .nav-link.active {
        padding: 10px 25px 10px 20px !important;
    }
    .roles_aumnics_in p br { display: none;}
    .software_service_provider{ flex-wrap: wrap;}
    .service_provider_in { width: 100% !important;}
    .service_provider_banner {
        width: 100% !important;
    }
    .service_provider_button { margin: 40px auto !important;}
    .client_requirement_banner figure {
        width: auto !important;
    }
    .aumnics_resources_in{
        margin-right: 0 !important;
    }
    
    .over-layout .over-layout-header { padding: 20px !important;}
    .over-layout .over-layout-header .hearder-content .header-title {
        margin-bottom: 10px;
    }
    .over-layout .over-layout-header .hearder-content { position: relative; flex-wrap: wrap;}
    .over-layout .over-layout-header .hearder-content .search-box .mat-icon {position: absolute;
        top: 0;
        right: 0;}
        .over-layout .over-layout-content {
            padding: 30px 10px!important;
        }
        .course-container .course-tile {
            padding: 15px !important; margin: 0 10px 10px 0 !important; width: 47.4% !important;
        }
        .mat-drawer {
            width: 90% !important;
        }
        .mat-drawer.full-width-drawer{
            width: 100% !important;
        }
        .course-icons img {
            height: 30px !important;
        }
        .course-container {
            gap: 15px !important;}
            .search-sec {
              margin-right: 0 !important;
            }
            .over-layout .over-layout-header .hearder-content .search-box { width: 100% !important;}
            .over-layout .over-layout-footer { flex-wrap: wrap !important;}
            .mat-stroked-button {
                padding: 6px 20px !important; margin-right: 10px;           
            }
            .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary{
                padding: 6px 15px !important;
            }
            .over-layout .over-layout-footer span { font-size: 15px !important;}
            .over-layout .over-layout-footer { padding: 20px !important;}
            .over-layout .over-layout-header .hearder-content .header-title { margin-bottom: 0 !important; line-height: 28px;}
            .over-layout .over-layout-header .hearder-content .search-box {
                margin-top: 10px;
            }
            .software-services .software-services-sec ul .arrow { width: 75px !important;}
            .software-services .software-services-sec ul .arrow1 {
                top: -95px!important; width: 60px !important;
            }
            .software-services .software-services-sec ul .service-arrow {
                top: -90px!important;width: 80px !important;
            }
            .software-services .software-services-sec ul .soft-service1{ width: 60px !important; top: -100px !important;}
            .mat-tab-label .mat-tab-label-content {
                font-size: 15px !important;
            }
            .mat-tab-label {
                height: 60px !important;
            }
            .tabs-container .clear {
                top: 15px !important;            
            }
            .tabs-container .body-content {
                padding: 30px 15px !important;
            }
            .tabs-container .footer-buttons {
                width: 90% !important;
            }

            .contactor-form .formgrid .field .field-checkbox.flex-nowrap { 
                flex-wrap: wrap !important;
            }
            .pay-block { width: 35%; padding: 13px 10px !important;}
            .employee_requirement_left {
                padding-right: 0 !important;
            }
           
            .subscriber-head { width: 430px; margin-left: auto;}
            .mobile-software-jobs .software-tabs-lft .nav.nav-pills .nav-item:nth-last-child(1) {
                margin-bottom: 0!important;
            }
            .most-questions {
                padding: 60px 0 0 !important;
            }
    .why-aumnics-in ul {flex-direction: column;}
    .why-aumnics-in ul li{ width: 100% !important; }
    .why-aumnics-in ul li .logo{ margin-left: 0px !important;}   
    .why-aumnics-in ul .right-sec-div-bg {margin-top: 50px; }
    .why-aumnics-in .logo img{ width: 65px !important; }
    .why-aumnics-in .description-sub-sec{ display: none;}
    .why-aumnics-in .description-sub-sec-lft, .why-aumnics-in .description-sub-sec-rgt{ display: block;}
    .logo_8 img{height: 40px !important;}
    .promote_team ul{ flex-direction: column !important;}
    .promote_team ul li { width: 100% !important;margin-right: 0px !important;}
    .promote_team p span { left: 0px !important;}
    .about-positions ul li figure img { width: 100% !important;}
    .roles_aumnics_in p span { left: 0px !important;}
    .why-aumnics .hide-on-mobile{ display: none !important;}
    .why-aumnics .description-sub-sec-rgt, .why-aumnics .description-sub-sec-lft{ display: block !important;
    background-color: #fff;border-radius: 16px;padding: 5% 3%;}
    .why-aumnics .description-sub-sec-lft h1 {font-size: 18px !important; max-width: 253px !important; margin-left: auto; margin-right: auto;}
    .why-aumnics .description-sub-sec-rgt h1 { font-size: 18px !important; max-width: 272px !important; margin-left: auto; margin-right: auto;}
    .quate-div{padding: 30px 30px !important; font-size: 18px !important;}
    .software_services_in .quate-div{ padding: 0 !important;}
    .why-aumnics-logo-sec { margin-left: -20px !important;}
    .software-tabs-content , .software-tabs { display: none !important;}
    .outsource-mobile img{ width: 100% !important;}
    .mdl-cotent .sub-content-tags-sec{display: flex;justify-content: space-between;}
    .mdl-cotent .sub-content-tags-sec div{  max-width: 109px;   padding: 10px; border-radius: 16px;  }
    .mdl-cotent .sub-content-tags-sec div img{  max-width: 50px;  margin-bottom: 20px;  }
    .content{margin-top: 40px !important;}
    .subscriber-head small{ left: 100px !important;}
    .software_services_in .opening-quote{ top: -28px !important;}
     .software_services_in .closing-quote{ bottom: -28px !important}
     .client_requirement_banner figure img { width: 100% !important;}
     .thumbsup-animation{right: 20% !important; top: 35% !important; }
     .dev-content { padding: 20px !important;}
     .about-positions ul li.our-story-card{ padding:40px 20px !important;}
     .about-us-banner.aumnics-works figure{ margin-top: 200px !important;}
     .aumnics-covered{ flex-direction: column; padding: 20px !important;}
     .aumnics-covered .aumnics-covered-lft{width: 100% !important;}
     .aumnics-covered .aumnics-covered-rgt{width: 100% !important; margin-top: 20px !important;}
     .create-account { flex-direction: column;}
     .create-account .create-account-lft {width: 100% !important ; padding: 20px !important;}
     .create-account .create-account-rgt {width: 100% !important}
     .aumnics-covered-lft { padding-right: 0px !important;}
     .aumnics-covered-rgt{ padding: 20px !important;}
     .trees{ display: none !important;}
     .whyaumnics-rgt-img img{ margin-bottom: -5.6%;}
     .platform-content,.plat-content{ flex-direction: column;}
     .platform-content{padding: 0 !important;}
     .plat-content-lft,.plat-content-rgt { width: 100% !important;}
     .plat-content-rgt .apps-menu-item{margin: 10px 0 !important;}
     .plat-description,.company-content,.menu-item,.country-tags .world,.countries-list-inner .cursor-pointer{width: 100% !important;}
     .menu-item{ margin: 20px 0 !important;}
     .dropdown-content{ margin-top: 20px !important; border: 1px solid #e8e8e8; position: relative !important;
         padding: 0 !important; top: 0 !important; box-shadow: none !important;}
     .mobile-drop-header{ display: flex !important;}
     .country-tags{ flex-direction: column;}
     .dropdown-content .heading-two{ font-size: 22px !important; padding: 0 10px !important;}
     .dropdown-content .world img{ width: 56px !important; margin-right: 20px !important;}
     .dropdown-content.web{ display: none !important;}
     .mobile-padding-div{ display: block !important;}
     .mobile-book-demo{ display: block !important;}
     .m-active-nav .nav-link{ color: #4B47F1 !important; font-weight: 500;}
     .m-active-nav .nav-link .fa-angle-down{transform: rotate(180deg);}
     .nav-item.login { margin-left: 0 !important;}
     .hide-on-mobile{ display: none !important;}
     .show-on-mobile { display: block !important;}
     .show-on-mobile.d-none { display: none !important;}

     //pricing responsive
     .pricing-sec{
        table{
          th{
            padding: 5px !important;
            h4 { margin:0 0 10px !important; font-size: 12px !important;}
            h6 { margin: 0 0 10px !important;  font-size: 12px !important; min-height: 20px !important; flex-direction: column; }
            h6 span { font-size: 6px !important; padding-right: 5px !important;}
            h6 var { padding: 1px 5px 0 !important; img { display: none !important;}
            }
            h6 var span {font-size: 6px !important;}
            h3 { font-size:18px !important; line-height: 20px !important; padding: 0 0 5px !important;}
          }
          td{
            padding: 5px !important; font-size: 10px !important;
            a.buy-now-cls{padding: 10px 5px !important; font-size: 8px !important;}
          }
          th.basic::after ,th.gold::after , th.platinum::after { 
            display: none !important;
          }
        
        }
      }

      .m-col-width .col-xs-12, .m-col-width .col-sm-12, .m-col-width .col-12 {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .search-input .p-inputtext {
        font-size: 17px !important;
      }
      .careers-aumnics .col-div { padding: 0 !important}

      .mobile-padding-none { padding-left: 0 !important; padding-right: 0 !important;}

      .app-benefit-card { margin-top: 20px;}
      .collab-div.rgt-img-sec { flex-direction: column !important; margin-top: 0px !important}
    .collab-div.lft-img-sec { flex-direction: column-reverse !important; margin-bottom: 0px !important;}
    .collab-div .content-sec, .collab-div .img-sec {
        width: 100% !important;
    }
    .collab-div .content-sec { padding: 0px !important; margin-top: 40px !important;}
    .collab-div .img-sec { margin-top: 40px !important;}
    .collab-div .img-sec .dots-background { padding: 20px !important; }
    .m-text-left { text-align: left !important;}
    .update-website-sec { min-height: auto !important;}
    .app-integrations-padding-inner,.why-choose-gradient-card .dots-background { padding: 30px 20px 20px !important;}
    .crossHire-sec .seamlessHire { padding: 20px !important;margin-top: 30px !important;}

    .mw-sticky-container { margin-left:15px !important; margin-right: 15px !important;}
    .footer-listing-parent {
        gap: 0 !important;
    }
    //  header css 
    .header-nav {
        display: flex;
        justify-content: flex-end !important;
        flex-direction: row-reverse !important;
    }
    .header-nav .mat-icon { color: #222; height: auto; font-size: 35px; width: 35px; cursor: pointer;}
    .header-nav .menu-toggle .mat-icon{ color: #fff }
     header.new-header-style.headerColor .header-nav .mat-icon{ color: #000; }
    header .container { display: flex; align-items: center; justify-content: space-between;}
    .navbar-toggler{padding: 0 !important;}
    .logo a {
        width: 150px !important;
    }
    .logo { margin-left: 20px;}
    .navbar-dark .navbar-toggler.menu-toggle { line-height: 0; margin-left:-3px;}
    .mobile-sign-up { display: flex !important;}
    .mobile-humberge {height: 75px; display:flex !important; align-items: center; justify-content:space-between; padding:15px;}
    .mobile-humberge a, .mobile-humberge .mat-icon.navbar-toggler { cursor: pointer;}
    .header-nav .nav-item {
        margin-right:0 !important;margin-bottom: 35px;
    }
    .header-nav .navbar-dark .navbar-nav .nav-link {
        font-size: 20px !important;
        line-height: 20px !important; padding: 0 !important;
    }
    .header-nav .navbar-nav { padding:20px 20px !important; overflow-y: scroll;height: inherit;}
    .header-nav .navbar-nav.signup-last {justify-content:flex-start; flex-direction: inherit; margin-left: 0 !important;}
    .header-nav .navbar-nav.signup-last .nav-item { margin-bottom: 0 !important; margin-right: 20px !important;}   
    .header-nav .navbar-nav.signup-last .nav-item .nav-link { border: 1px solid #8b8b8b; border-radius: 6px; padding: 14px 25px !important; font-size: 15px !important;}
    .header-nav .navbar-nav .signup .nav-link { border: none !important; border-radius: 6px;padding: 12px 20px !important;}
    .header-nav .navbar-nav .login .nav-link {padding: 12px 20px !important; }
    .header-nav .navbar-nav .book-demo { display: none !important;}
    
    .header-nav .navbar-nav.signup-last .nav-item:nth-last-child(1) { margin-right: 0 !important;}
    .subscribe-mobile { display:block !important;}
    .header-nav .navbar-nav { padding:40px 20px;}
    .navbar-nav .nav-link::after { width: 0% !important;}
    header { 
        padding: 18px 0 !important;
    }
    .humberge-menu-section { position: absolute; bottom:0; left: 0; width: 100%; background-color: #fff;}
    .social-icons.mobile-social-icons { display:block !important; padding: 10px 10px 30px !important;}
    .social-icons.mobile-social-icons ul li a .fab { color: #222; font-size: 25px !important;}
    .social-icons.mobile-social-icons ul li {
        margin: 0 15px !important;
    }
    .banner-in {
        padding: 30px 0 0!important;align-items: flex-start !important; flex-wrap: wrap;
    }
    .collaboration span { right: auto !important;}
    .integration-apps span { left: 0 !important;}

    .mobile-software-jobs { display: block !important;}
    .software-needs .mobile-software-jobs .software-tabs-lft  { display:block !important;}
    .software-tabs .software-tabs-lft, .software-tabs .software-tabs-cont { display: none !important;}
    .mobile-software-jobs .software-tabs-lft .nav.nav-pills .nav-item { margin-bottom: 30px; padding-bottom: 20px;}
    .software-tabs-lft .nav-pills .nav-link { padding: 8px 20px 20px !important;}
    .mobile-software-jobs .software-tabs-lft .nav-pills figure {
        margin-left: 20px !important;
    }
    .header-nav .navbar-dark .navbar-nav .active:after { display: none !important;}

    .logo a img { width: 80% !important; height: auto;}
    .home-banner .container {
        flex-direction: column;
    }
    .header-nav .navbar-collapse{
        position: fixed; width:100%;left:-100%;height: 100vh;background: #fff; top: 0; z-index: 99;-webkit-transition:0.2s ease-in-out;-moz-transition:0.2s ease-in-out;-o-transition:0.2s ease-in-out;transition:0.2s ease-in-out;
    }
    .open .header-nav .navbar-collapse{
        left: 0;
    }

    .wrapper{position: relative; left: 0;-webkit-transition:0.2s ease-in-out;-moz-transition:0.2s ease-in-out;-o-transition:0.2s ease-in-out;transition:0.2s ease-in-out;}
    .open .wrapper{left:0;}
    .navbar-expand-md .navbar-toggler { display: block;}
    .m-ml-0 { margin-left:0 !important}
}

@media(max-width:575px) {
    .software_services.it_services { margin: 0 15px 30px; border-radius: 10px !important;}
    .app-partner-sec figure { text-align: center !important;}
    .listing-sec-head .text-right { text-align: center !important;}
    .dev-name { font-size: 18px !important;}
    .connecting_software_arrow.top{right: 45%; top: 15%; }
    .connecting_software_arrow.bottom{ bottom: 20%;  right: 35%; }
    .map-arrow-it { left: 37% !important;}
    .news-aumincs-lft {
        width: 100% !important;
    }

    .institutive-img-sec { width: 100% !important; position: inherit !important;}
    .skills_sec .skills { width: 100%;}
    .paragraph-global-text { font-size: 13px !important;}
    .about-us-lft span { display: block;}
    .about-positions ul li h2.heading-one { padding: 0 !important;}  
    .subscriber-head {
        padding: 0!important;
    }
    .copy-sec {
        margin-top: 5px !important;
    }
    
    .copy {
        padding: 0px 0 15px!important;
    }
    footer ul li {
        width: 100%!important; margin-bottom: 20px !important;
    }
    footer ul li.hide-on-mobile { display: none;}
    .join-subscriber-rgt {
        /* max-width: 100% !important; */
        padding: 0 !important;
    }footer ul li .footer-cont ul li
    .subscriber-head {
        padding: 0 !important;
    }
    .join-subscriber { margin:0 15px; border-radius: 6px !important;}
    .integration-apps ul li h4.heading-three {
        font-size: 22px!important;
    }
    .mobile-humberge { padding: 18px !important;}

    .join-subscriber-lft span { display: none;}
    .subscriber-head small {
        bottom: -5px !important;
    }
    footer {
        padding: 40px 0 10px!important;
    }
    
    .copy_in, .copy-sec ul { align-items: flex-start !important; flex-direction: column; justify-content: left !important;}
    .copy-sec ul li {
        margin: 0 20px 0 0 !important;
        width: 100%;
    }
    .copy-sec {
        border-top: 1px solid #505050; padding: 20px 0 0; margin-top: 5px;
        width: 100%;
    }

    .container {
        max-width: 92%;
    }
    .software-needs-top a.learn-more { display: none;}
    .meeting button {
        padding: 12px 25px!important;
    }
    .software-needs-lft {
        width: 100%!important;
    }
    .software-needs-top { margin-bottom:30px !important;}
    .learn-more.show-mobile { display:block !important; text-align: center; width: 50%; margin: auto;}
    .software-show-mobile { display: block !important; width: 200px;}
    .software-show-mobile img {width: 100%; height: auto;}
    .software-tabs-cont { display: none;}

    .heading-one { font-size: 25px !important; line-height: 35px !important;}
    .banner-lft p.paragraph-global-text {
        margin-bottom: 40px !important;
    }
    .banner-rgt figure { width: 250px; margin: auto;}
    .video a {
        padding: 8px 15px!important;margin-top: 40px !important;  
    }
    .banner-top figure {
        width: 180px !important;
    }
   
    .banner-top {
        top: -150px!important;
    }


    .mobile-sign-up { padding: 8px 22px !important;}
    .new-header .mobile-sign-up { padding: 8px 25px !important;}
    .banner-lft h2 {
        font-size: 14px !important;
        line-height: 25px !important;
        margin-bottom: 10px !important;
    }
    .banner-lft h2 span {
        right: 130px !important;
    }
    .banner-lft h1 {
        font-size: 32px!important;
        line-height: 41px!important;
    }
    .video a {
        padding: 8px 15px!important;    
    }
    .banner-top figure {
        width: 150px!important;
    }
   
    .banner-top {        
        right: 10px!important;
    }
    .banner-top {
        top: -130px!important;
    }
    .meeting {
        flex-wrap: wrap;
        width: 100%;
    }
    .meeting button {
        
        width: 100%;
        margin-bottom:15px;
        margin-right: 0 !important;
    }
    .payment-block {
        padding: 30px 20px 20px !important;
    }
    .learn-more.show-mobile {
        width: 100% !important;
        margin: 20px auto 0 !important;
    }
    .collaboration {
        padding: 40px 0 !important;
    }

    .integration-apps h3.heading-one br { display: none;}
    .integration-apps ul li p.paragraph-global-subtest {
        margin: 0 0 10px !important;
    }
    /* .join-subscriber-rgt {
        max-width: 100% !important;
    } */
    .subscribe-modal .subscribe-cont {
        padding: 15px !important;
    }

    .mobile-humberge a { width: 100px;}
    .mobile-humberg { padding: 10px 18px;}
    
    .mobile-humberge a img {width: 100%; height: auto;}
    .header-nav .mat-icon { font-size: 30px !important; width:30px !important;}
    footer ul li .footer-cont ul li {
        width: 100%!important; margin-bottom: 10px !important;
    }

    .collaboration span { right: auto !important;}
    .integration-apps span { left: 0 !important;}
    .banner-rgt .banner-top figure {
        width: 150px !important;
    }
    .social-icons ul li {
        margin: 0 20px 0 0 !important;
        width: fit-content !important;
    }
    .social-icons ul li:nth-last-child(1) { margin: 0;}
    footer ul li .footer-cont ul li p br { display: none;}
    .software-tabs-lft .nav-pills .nav-link.active, .software-tabs-lft .nav-pills .show > .nav-link{ border-left: transparent !important; margin-left: 0 !important;}
    .software-needs-lft span { left: 0 !important;}
    .banner-in {
        padding: 0 !important;
        align-items: center !important;
    }
    .banner {
        height:780px!important;
    }
    .logo_8 img{height: 40px !important;}
    .integration-apps ul li { margin-bottom: 40px !important;}

    .about-positions ul li small { left:0 !important;}
     .about-positions ul.mobile-direction { flex-direction: column-reverse;}
     .about-positions ul li {
        margin-bottom: 20px !important; 
    }
    .reliability ul li .reliability-cont ul li p br { display: none;}
    .lead-team {
        padding: 40px 0 70px !important;
    }
    .team-top a, .news-aumincs-top a, .careers-block a.view {
        padding: 12px 20px!important;
        font-size: 15px!important; width: 100%; text-align: center;
    }
    .lead-team ul li {
        width: 100% !important;
    }
    .lead-team ul { flex-wrap: wrap; margin-top: 40px !important;}
    .lead-team ul li .team-section p.paragraph-global-text {
        min-height:auto !important;
    }
    .lead-team {
        padding: 40px 0 20px !important;
    }
    .our-culture ul li {
        margin-bottom: 30px !important;
    }
    .news-aumincs {
        padding: 40px 0 10px !important;
    }

    /* .news-aumincs li { border:2px solid #2F2D40;} */
    .news-aumincs li .news-cont {
        padding: 30px 10px 0!important;
    }
    .news-aumincs li .news-cont .news-time { flex-wrap: wrap;}
    .news-aumincs li .news-cont .news-time h4 { width: 50%;}
    .news-aumincs li .news-cont .news-time a { margin-left: auto;}

    .news-aumincs ul {
        margin-top: 40px;
        border-top: transparent !important;
        padding-top: 0 !important;
    }
    .news-aumincs li { border:transparent !important; padding-bottom: 10px !important;}
    .news-aumincs li .news-section {
        border: 0.5px solid #cdcdcd;
        padding: 0 0 15px !important;
    }
    .careers-aumnics h2.heading-one {
        font-size: 25px!important; line-height: 35px !important;
    }
    .careers-block {
        padding: 20px 10px!important;
        flex-wrap: wrap;
    }
    .careers-block-left h3.heading-one {
        font-size: 25px!important;
        margin: 0 0 10px!important;
    }
    .careers-block .careers-block-left small { font-size: 16px !important; line-height: 25px !important;}
    .careers-aumnics ul { flex-wrap: wrap;}
    .careers-aumnics ul li { margin: 10px 0 30px !important;}
    .careers-aumnics {
        padding: 50px 0 10px !important; margin-bottom: 30px !important;
    }
    .about-positions { border-bottom: transparent !important;}
    .reliability {
        padding: 10px 0!important;
    }
    .news-aumincs li .news-cont h3 { font-size:18px !important;font-family: 'SuisseIntlLight' !important; line-height: 25px !important;}
    .reliability h2.heading-one {
        font-size: 28px!important;
    }
    .lead-team ul li .team-section h4 { font-size: 20px !important;}
    .news-aumincs li .news-cont h5 { margin-bottom: 22px !important;}

    .news-aumincs li .news-section figure.mobile-show-new-aumnics { display:block !important; width: 100%;}
    .news-aumincs li .news-section figure.mobile-show-new-aumnics img { width: 100%; height: auto;}
    .news-aumincs li .news-section figure { display: none;}
    .careers-aumnics ul li {
        padding: 0 !important; 
    }
    .reliability ul li .reliability-cont ul li p {
         padding: 8px 40px 0px 10px !important;
    }
    .reliability ul li .reliability-cont ul li::after { right:10px !important;}
    .pricing-main ul { flex-wrap: wrap;}
    .pricing-main ul li { width: 100% !important;}
    .pricing-main ul li .pricing-blog, .pricing-main ul li .pricing-blog:hover {
        min-height: auto !important;
    }
    .most-questions small { font-size: 14px !important;}
    .most-questions .view-all { border-top: transparent !important;}
    .most-questions .view-all span {
        width: 80% !important;
    }
    .most-questions .view-all span button {
        padding: 14px 35px !important; width: 80%; font-size: 16px !important;
    }
    .most-questions .view-all { margin-top: 30px !important; display: block !important;}
    .most-questions {
        padding: 20px 0 !important; margin-bottom: 0 !important;
    }
    .most-questions .accordion .card {padding: 15px !important;}
    .most-questions .accordion .card h2 {
        line-height: 25px !important; padding: 0 30px 0 0 !important;
    }
    .lets-talk h3 {
        line-height: 30px !important; margin: 0 0 20px !important; text-align: center;
    }
    .journey-block ul { flex-wrap: wrap; margin-bottom: 20px !important;}
    .journey-block ul li { width: 100% !important; margin-bottom: 15px;}
    .journey-block ul::after { display: none;}

    .support-main .support-in .support-lft ul { flex-wrap: wrap;}
    .support-main .support-in .support-lft ul li { margin-bottom: 18px !important;}
    .categories {
        margin-top:0!important; padding:30px 0 0 !important; box-shadow: none !important; margin-bottom:0 !important;}
        .support-in .support-lft {
            margin-bottom: 40px;
        }

        .support-main {
            padding: 50px 0 0 !important;
        }
        .categories ul li {
            box-shadow: 0 0 5px #d7d7d7; width: 100% !important; padding: 25px 15px !important;
        }
        .categories ul li h4 {
            min-height:auto !important;
        }
        .most-questions .accordion {
            margin-top: 0 !important;
        }
        .support-btm {
            padding: 40px 20px !important; }
        .support-main .support-in .support-lft ul li:nth-child(1) { width: 100%;}
        .support-main .support-in .support-lft .support-search { background: #fff !important; border-radius:5px; padding: 2px;}

        .support-main .support-in .support-lft .support-search button { background:#4B47F1 !important; padding: 12px 20px !important; font-size: 15px;}
        .support-main .support-in .support-lft .support-search .search { background: transparent !important; border-radius: 0 !important;}
        .support-main .support-in .support-lft .support-search .search span.fa { font-size: 18px !important;}
        .pricing-main ul li .pricing-blog {
            padding: 30px 10px 10px!important;
        }
        .journey-block ul li img {
            width: auto !important;
        }
        .journey-block ul li h3 {
            font-size: 18px !important;
            line-height: 23px!important;
        }

        .about-us-banner.book-demo-banner .about-us-rgt figure img {
            width: 100%!important;
        }
        .book-demo-form .book-demo-lft .contact-sub { flex-wrap: wrap;}
        .book-demo-form .book-demo-lft .contact-sub li { width: 100% !important;}

        .book-demo-form .book-demo-lft .contact-sub li .mat-radio-label { white-space: inherit !important; align-items: flex-start !important;}
        .book-demo-form button { width: 100%; padding: 15px 8px !important; font-size: 17px !important;margin: auto;
            display: block;}

        .about-us-banner.contactus .about-us-rgt figure img {
            width:100% !important;
        }
        .book-demo-form ul.contact-sub { flex-wrap: wrap;}
        .book-demo-form ul.contact-sub li { width: 100% !important;}
       
        // .book-demo-form.mobile-contact {
        //     margin: -80px auto 50px!important;
        // }

        .software-services .software-services-in ul li button { display: none;}
        .software-services .software-services-in ul li figure {width: 120px !important; min-height: 125px; margin: 20px auto !important;}
        .software-services .software-services-in ul li span {
            width: 90% !important;
        }
        .software-services .software-services-sec ul { flex-wrap: wrap;}
        .software-services .software-services-sec ul li { width: 100% !important; margin-bottom: 30px;}
        .software-services .software-services-sec ul.software-team-mobile { flex-direction: column-reverse;}
        .software-services .software-services-in ul li h3 {
            font-size: 15px!important; line-height: 25px !important; padding: 0 5px;
        }
        /* .software-services .software-services-sec small {
            left: 70px !important;
            right: auto !important;
        } */

        .software-services .software-services-sec ul .arrow {
            top: -100px !important;
            width: 75px !important;
            right: 0 !important;
        }
        .software-services .software-services-sec ul .arrow1 {
            right: 0 !important;
            top: -115px!important;
             width: 65px !important;
        }
        .software-services .software-services-sec ul .arrow7 {
            right:0 !important;
            transform: rotate(-5deg) !important;
        }

        .software-services .software-services-sec {
            margin: 120px 0 auto !important;}
    .software-services .software-services-sec small {
        left: 100px !important;
        right:auto !important;
        transform: rotateY(-5deg) !important;        
    }
    .software-services .software-services-sec ul .service-arrow {
        top: -110px !important; transform: rotateY(-180deg);
    }
    .user-access {                
        padding: 30px 0 10px !important; }
        .user-access .search { margin-bottom:30px !important;}
        .user-access .bread-crums ul { flex-wrap: wrap;}
        .user-access .bread-crums ul li { margin-bottom: 10px;}
        .user-access .bread-crums ul {
            margin-bottom: 10px !important;
        }
        .security-block .security-block-in .security-block-rgt .top-settings { padding:0 !important; border: none !important;}
        .security-block .security-block-in .security-block-rgt .top-settings h3 {
            margin: 0 0 10px !important;
            font-size: 24px!important;
            line-height: 35px !important;
        }
        .user-access .bread-crums ul.icons li {
            margin-bottom:0 !important;
        }
        .security-block .security-block-in .security-block-rgt .tags ul { flex-wrap: wrap;}
        .security-block .security-block-in .security-block-rgt .tags ul li:nth-child(1) { width: 100%; margin-right: 0 !important;}
        .security-block .security-block-in .security-block-rgt .tags ul li { margin-bottom: 20px;}
        .helpful { flex-wrap:wrap-reverse;}
        .security-block .security-block-in .security-block-rgt .tags { margin: 30px 0 !important;}
        .security-block {
            padding: 40px 0 !important;
        }
        .meeting .learn-more { width: 100% !important; text-align: center; font-size: 15px;}

        .developer_aumnics{
            margin-top: 40px !important;
        }
        .view-all {
            border-top:transparent !important;}
            .view-all span {
                width: 100% !important;
            }
            .view-all span button {
                padding: 13px 0 !important; font-size: 15px !important; width: 100%;
            }

            .business_aumnics .heading-four{
                    padding:0 !important;                
            }
            
            .sub-heading-one{ font-size: 15px !important;}
            .hide-sub-img { display: none;}
            .aumnics_benifits_category {
                width: 100% !important;
            }
            .aumnics_benifits_list{ padding-bottom: 0 !important;}
            .software_services_in {
                padding: 30px 0px !important;
                border-radius: 8px !important;
            }
            .hiring_aumnics_in h2.heading-one {
                font-size: 25px!important;
            }
            .aumnics_benifits {
                background: #ffffff !important;
            }
            .aumnics_benifits_category{ background: #fff !important; border: none !important; margin-bottom: 0 !important;}
            .aumnics_benifits_in {
                padding-bottom: 30px !important;
            }
        
            .company_aumnics_in h2.heading-one {
                font-size: 25px!important;
            }
            .company_aumnics_content figure img {width: 100%; height: auto;}
            .company_aumnics_content { margin-bottom: 30px; padding: 0 !important;}

            .company_aumnics_data {
                width: 100%;
                flex-direction: column-reverse; border: none !important; padding: 30px 0 0!important;
            }
            .company_aumnics_tabs { padding: 0 !important; margin-bottom: 0 !important; width: 100%;}
            .aumnics_resources_in{padding-bottom: 30px !important;}
            .company_aumnics_verification {
                margin-top: 0 !important;
            }

            .company_aumnics_tabs .nav-pills .nav-link{ padding:10px !important; width: 100% !important; font-size: 14px !important;}
            .company_aumnics_tabs .nav-pills .nav-link.active {
                padding: 10px !important;
            }
            .software_service_provider { padding: 30px 0 50px !important;}
            .roles_aumnics_content { width: 100% !important;}
            .roles_aumnics_in{
                padding-top: 40px !important;
            }
            .roles_aumnics_data {
                margin: 0 0 25px !important; padding: 15px 20px !important; width: 100%;
            }
            .roles_aumnics_data span { font-size:20px !important;}
            .hiring_services { padding: 50px 0 !important;}
            .software_business {
                padding-top: 30px!important;
            }
            .business_aumnics_in {
                padding: 30px 0 40px !important;
            }
            .software_business {
                margin-top: 50px !important;
            }
            .hiring_services {
                padding: 20px 0 0 !important; margin: 50px 0 !important;
            }
            .employee_services{
                padding-top: 20px !important;
            }
            .employee_services_heading { margin: 0 !important;}
            .service_provider_banner {
                padding-top: 0px !important;
            }
            .service_provider_button { font-size: 15px !important; line-height: 25px !important;}
            .aumnics_benifits_heading {
                margin: 0 0 5px !important;
            }
            .employee_requirement_left h2 {
                font-size: 25px !important;
            }
            .service_providers {
                margin-top: 10px !important;
            }
            .employee_services_data {
                padding-left: 20px !important;
            }
            .business_aumnics_sub h4.heading-two { margin: 0 !important;}
            .business_aumnics_box { display: flex !important; align-items: center !important; margin-right: 0 !important;margin-bottom: 0 !important; width: 100% !important;
            flex-wrap: wrap; height: auto !important; border: none !important; border-radius: 0 !important; padding: 20px 10px !important;
                border-bottom: #ddd 1px solid !important;
        }
            .business_aumnics_box p { min-height: auto !important; width: 120px; margin: 0;}
            .business_aumnics_sub { width: 100% !important; display: flex !important;align-items:flex-start; margin-bottom: 50px !important;}
            .business_aumnics_box img {
                margin: 0 auto !important; width:40px; height: auto;
            }
            .heading_business_aumnics { margin-right: 0 !important; padding-top: 0 !important; font-size: 18px!important;
                line-height: 25px!important;padding-left: 40px;}
            .business_aumnics_sub h4.heading-two { font-size: 18px !important; line-height: 25px !important; position: relative;}
            .business_aumnics_sub h4.heading-two small { font-size: 100%;}
            .business_aumnics_sub h4 small { margin-right: 0 !important;}
            
            .business_aumnics_sub .arrow_right_top { bottom: -39px; top: auto !important; right:60px !important; transform: rotate(92deg);}
            .heading_business_aumnics .arrow_right_bottom {
                bottom: -40px !important; right: auto !important; top: auto !important; left: 60px !important;transform: rotate(92deg);
            }
            .business_aumnics_sub h4 span { display: block; margin-top: 5px;}
            .business_aumnics_sub h5 span { display: block; margin-top: 5px;}
            .business_aumnics_main { border: 1px solid #ddd; flex-wrap: wrap; border-radius:4px;}
            .hiring_services_in:nth-child(even)::after{ border-right:transparent !important;}
            .mat-drawer {
                width: 100%!important;
            }
            .over-layout .over-layout-header {
                padding: 15px !important;
            }
            .over-layout .over-layout-header .hearder-content .header-title .mat-icon {
                font-size: 30px !important;
                margin-right: 10px !important;
            }
            .over-layout .over-layout-header .hearder-content .header-title h2 { font-size: 20px !important;}

            .over-layout .over-layout-header .hearder-content .search-box .mat-icon { font-size: 30px !important;}
            .over-layout .over-layout-content {
                padding: 30px 10px 30px 15px !important;
            }
            .course-container .course-tile .course-title {
                min-height:auto !important;
            }
            .course-container .course-tile {
                width: 100% !important; margin: 0 0 10px 0!important;
            }
            .over-layout .over-layout-footer .footer-buttons { margin-top: 20px;}
            .over-layout .over-layout-footer {
                padding: 15px !important;
            }
            
            .mat-stroked-button {
                padding: 5px 25px !important;
            }

            .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
                padding: 6px 20px !important;
            }
            .skills-container .technologies {
                flex-wrap: wrap !important;
            }
            .skills-container .technologies .category {
                flex: inherit !important;
            }
            .skills-container { gap:30px !important;}

            .skills-container .technologies .category p {
                margin: 0 0 5px !important;
                line-height: initial !important;
            }
            .skills-container .technologies .languages {
                flex: inherit !important;
            }

            .skills-container .technologies .languages .mat-chip.mat-standard-chip {
                padding: 10px 20px !important;
            }
            .cdk-overlay-pane.video-modal {
                max-width:95vw !important; height: 350px !important;
            }
            .video-block span {
                top: 18% !important;right:5% !important; }

                .video-block iframe {
                    height: 350px;
                }

                .tabs-container .mat-tab-label-container {
                    padding: 0 5px !important;
                }
                .tabs-container .clear .mat-icon { display: none;}
                .mat-tab-label .mat-tab-label-content {
                    font-size: 14px !important;
                }
                .contactor-form .field>label, .project-form .field>label {
                    font-size: 16px !important;
                    margin-bottom: 10px !important;
                }
                .tabs-container .footer-buttons {
                    width: 100% !important;
                }
                .mat-tab-label { padding: 0 !important;}
                .mat-tab-labels {width: 100%; justify-content: space-around;}
                .pay-block {
                    width: 65%;
                    padding: 13px 10px !important;
                }
                .contactor-form .formgrid .field .field-checkbox.flex-nowrap.gap-6 {
                    gap: 1rem !important;
                } 
                .contactor-form .formgrid .field .flex-nowrap { flex-wrap: wrap !important;}
                .contactor-form .formgrid .field .flex-nowrap.gap-5 { gap: 0 !important;}
                .urgency-width .p-dropdown {
                    width: 100% !important;
                }
                .field.enter-email-padding {width:96%; max-width: 96%;}
                .contactor-form .formgrid.grid { display: block !important;}
                .contactor-form .formgrid.flex-nowrap.gap-5 {
                    gap: 0 !important;
                }
                .p-dropdown { width: 100% !important;}
                .describes-width .p-dropdown {
                    width: 100% !important;
                }
                .cdk-overlay-pane.subscribe-modal {
                    max-width:95vw !important;
                }
                .company_aumnics_content {width: 100% !important;}
                .roles_aumnics_in p span { display: none;}
                .roles_aumnics_content {padding-top: 40px !important;}
                .software-services .software-services-sec small.soft-service{
                    left: 0 !important;
                    right: -50px !important;
                    transform: rotateY(155deg) !important;
                }
                .container.hire-mobile { max-width: 100% !important;}
                .about-us-banner.pricing-banner .about-us-rgt figure img { width: 100% !important;}
                .hiring_services_in{ width: 100% !important; text-align: center; padding: 10px !important; margin-bottom: 20px !important;}
                .hiring_services_in:after { display: none;}
                .hiring_services_in h2 {  margin:0 0 10px !important;}
                .journey-block h2.heading-one, .most-questions h2 {
                    font-size: 25px !important; line-height: 33px !important;
                }
                .journey-block {                
                    padding: 35px 0 !important;
                }

                .support-main .support-in .support-lft .heading-one {
                    font-size: 28px!important;
                    line-height: 30px!important;
                }
                .categories h2.heading-one {
                    margin: 0 0 20px !important;
                    font-size: 25px!important;
                }
                /* .book-demo-lft .rc-anchor-normal {                
                    width: 295px !important;
                } */
                /* .captch-block { display: none !important;} */
                .book-demo-form.mobile-contact ul, .book-demo-form.mobile-contact ul.contact-sub {
                    width: 90% !important;
                }
                .pricing-main ul li .pricing-blog h4.heading-two {
                    margin: 0 0 15px !important;
                    font-size: 28px !important;
                }
                .course-container .course-tile .course-title h2 { min-height: auto !important;}
                .field-checkbox.gap-6.project-mobile { gap: 0 !important; flex-wrap: wrap;}
                .field-checkbox.gap-6.project-mobile .pay-block { margin: 0 10px 15px 0; width: 45%;}
                .group-addon.group-addon1{ width: 60% !important;}
                .cookies-section .cookies-lft figure { display: none;}
                .cookies-section .cookies-lft p { padding-left: 0 !important;}
                .cookies-section {
                    padding: 20px 10px !important; flex-wrap: wrap;
                }
                .cookies-section .cookies-lft {width: 100% !important; margin-bottom: 10px;}
                .cookies-section .cookies-rgt{ margin: auto !important;}
                .cookies-section { bottom: 0 !important;}
               // .user-agreement {height: 120px !important;}
                .user-cont {
                //    padding: 30px 0 10px !important;
                }
                .user-cont h3.heading-two{ font-size: 20px !important;}
                .user-cont p { font-size: 16px !important; line-height:28px !important;}
                .user-cont .user-space {
                    margin-bottom: 30px !important;
                }

                .confirmed{                  
                    padding: 20px 13px 40px !important;
                }
                .confirmed img { width: 120px; height: auto;}
                .confirmed h5 {                   
                    margin: 0 0 20px !important;
                    line-height: 30px;
                }
                .confirmed .confirmed-cont ul li b, .confirmed .confirmed-cont ul li span {
                    font-size: 16px !important;
                }
                .confirmed .confirmed-cont ul li img { margin-right: 10px !important;}

                .confirmed .confirmed-cont small { text-align: center; line-height:25px;}
                .buttons-div.bottom-space {
                    margin: 40px 0 0 !important;
                }
                .software-tabs-lft .nav-pills .nav-link p { margin: 0 !important;}
                .mobile-software-jobs .software-tabs-lft .nav.nav-pills .nav-item:nth-last-child(1){ margin-bottom: 0 !important;}
                .software-tabs-lft {
                    margin-bottom: 50px;
                }
                .enter-email-padding {                
                    width: 93% !important;
                }
                .over-layout .over-layout-header .hearder-content .search-box {
                    margin-top: 0 !important;
                }
                .pay-block.how-would-mobile { width: 47% !important;}
                .where-life-cycle-mobile .pay-block {
                    width: 70% !important;
                }
                .over-layout .over-layout-header .hearder-content .header-title h2 {
                    font-size: 17px !important;
                }
                .alert {
                    padding: 15px 10px !important;
                }
                .join-subscriber-lft {
                    margin-bottom: 10px !important;
                }
                .join-subscriber-rgt {
                    max-width: inherit!important;
                    margin-left: inherit !important;
                    width: 100% !important;
                }
                .subscriber-head {
                    width: auto !important;
                    margin-left: inherit !important;
                }
                .payment-block h4 {
                    min-height: 65px;
                }
                .user-access .bread-crums ul li {
                    margin-right: 0px !important;
                }
                .user-access .bread-crums ul.user-acces-mobile li, .user-access .bread-crums ul.icons li {
                    margin-right: 20px !important;
                }
                .user-access .bread-crums ul.icons { margin-bottom: 10px !important;}
                .categories-sec .categories-in .categories-rgt ul li { padding: 20px 10px !important;}
                .pricing-main ul li .pricing-blog a.buy-now-cls { padding: 10px !important;}
                .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl h1{font-size: 40px !important; line-height: 40px !important;}
                .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-name {
                    padding: 10px 0!important;
                    margin: 10px 0!important;
                }
                .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-name h2 {
                    font-size: 40px !important;
                    margin: 0 0 10px !important;
                    line-height: 50px !important;                
                }
                .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl .certificate-btm ul li { width: 100% !important;}
                .formgrid.grid.mobile-contact-person, .field-checkbox.mobile-contact-person { display: block !important;}
                .aumnics-certificate .aumnics-certificate-in .certificate-cont {                    
                    padding:30px 20px 20px !important;
                }
                .aumnics-certificate .close .mat-icon { font-size:30px !important;}
                .aumnics-certificate .close {
                    right: 9% !important; top: 20px !important;
                }
                .software-needs {
                    padding: 30px 0 0 !important;
                }
                .search-sec .btn {
                    padding: 5px 15px!important;                    
                }
                .over-layout .over-layout-header .hearder-content .search-box .search-sec {
                    margin: 10px 0 0!important;
                }
                .preferred-time-width.w-6 { width: 100% !important;}
                .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-rgt figure { display: none !important;}
                .aumnics-certificate .aumnics-certificate-in .certificate-cont .certificate-mdl figure { display: block !important;
                    width: 180px !important; height: 180px !important; background-size: cover;
                }
                .aumnics-certificate .close {
                    right: 10%!important;
                    top: 28px !important;
                }
                .aumnics-certificate .close .mat-icon {
                    color: #000 !important;
                }
                .company-aumincs .card-body { padding: 10px !important;}
                .company-aumincs #faq .card .card-header .btn-header-link-arrow { padding:10px !important;}
                .company-aumincs #faq .card .card-header .btn-header-link-arrow small { padding: 10px 0 10px 10px !important; font-size: 16px; width: 230px;}
                .company-aumincs #faq .card .card-header .btn-header-link-arrow::after{width: 30px !important; height: 30px !important; font-size: 16px; line-height: 30px !important;}               
                .join-subscriber-rgt .join-subscriber-rgt-in .form-control { width: 100% !important;}
                .join-subscriber-rgt-in button { padding: 10px !important;}
                .hires-technology ul li a { font-size: 10px !important; padding: 3px 8px !important; margin-left: 6px !important;margin-right: 6px !important;}
                .hires-technology ul li { margin-bottom: 15px !important;}
                
 .promote_team_com {display: none !important;}
 .promote_team_mobile {display: flex !important;}
 .promoSubTag div { padding: 20px 20px !important;}
 .why-aumnics span{
    left: 0 !important;
 }
 .why-aumnics { padding: 40px 0 40px !important;}
 .banner-bg-div { width: 75% !important; height: 34% !important;}
 .footer-cont ul li a img{ width: 50% !important; height: auto;}
 .map-wave-l{width: 12% !important; top: 26.1% !important;  left: 23.9% !important;}
 .map-wave-rwa{ top: 17% !important; right: 26.5% !important; }
 .map-wave-rca{ top: 24% !important; right: 23% !important;  }
 .map-wave-rny{ top: 18% !important; right: 14% !important;  }
 .map-wave-rtx{ top: 28.5% !important;right: 18% !important; }
 .roles_aumnics_content { margin-top: 0px !important;}
 .about-positions ul li.our-story-card{ padding:40px 0px !important; box-shadow: none !important;margin-bottom: 0 !important;}
 .about-positions ul li.our-story-card p{ margin-bottom: 0 !important;} 
 .about-positions ul li.our-story-card h4{ font-size: 28px !important;} 
 .careers-aumnics .careers-content{ padding: 40px 20px !important;}
 .careers-aumnics .nth-image{ margin-bottom: 0px !important;}
 .about-us-banner.aumnics-works figure{ margin-top: 230px !important;}
 .news-aumincs .news-section figure{ max-width: 100% !important; }
 .integration-apps ul { margin-left: 0 !important; margin-right: 0 !important; margin-top: 40px !important;}
 .integration-apps ul .app-card{ margin-top: 0px !important;}

 //pricing responsive
 .pricing-sec{
    table{
      th{
        padding: 5px !important;
        h4 { margin:0 0 10px !important; font-size: 12px !important;}
        h6 { margin: 0 0 10px !important;  font-size: 12px !important; min-height: 20px !important; flex-direction: column; }
        h6 span { font-size: 6px !important; padding-right: 5px !important;}
        h6 var { padding: 1px 5px 0 !important; img { display: none !important;}
        }
        h6 var span {font-size: 6px !important;}
        h3 { font-size:18px !important; line-height: 20px !important; padding: 0 0 5px !important;}
      }
      td{
        padding: 5px !important; font-size: 10px !important;
        a.buy-now-cls{padding: 10px 5px !important; font-size: 8px !important;}
      }
      th.basic::after ,th.gold::after , th.platinum::after { 
        display: none !important;
      }
    
    }
  }
  .pricing-banner-img { width: 100% !important;}
}
@media (max-width: 480px){
    .map-wave-l{width: 12% !important; top: 23.1% !important;  left: 23.9% !important;}
    .map-wave-rwa{ top: 15% !important; }
    .map-wave-rca{ top: 21% !important; }
    .map-wave-rny{ top: 16% !important;   }
    .map-wave-rtx{ top: 24.5% !important; }
    .sp-button-arrowAnimation { left: 0% !important; top: -4% !important;}
}
@media(max-width:400px) {
    .why-aumnics span{
        bottom: 7px !important;
        left: 180px !important;
     }
     .banner{ height: 730px !important;}
}

@media( max-width:370px) {
    .banner-lft h1 {
        font-size: 28px!important;
    }    
}





/* -----Font responsiveness------ */

@media( max-width:1550px) {
   
    .nav-btn{
        font-size: 19px !important;
    }

    .card-value{
        font-size: 14px !important;
    }
    .card-label{
        font-size: 11px !important;
    }
    .footer-cont ul li a {
        font-size: 14px !important;
    }
    .nav-btn{
        padding: 10px 18px !important;
    }
}

@media screen and (max-width: 1300px) {
    .banner-heading{
        font-size: 44px !important;
        line-height: 45px !important;
    }

    .connect-banner .banner-heading{
        font-size: 48px !important;
        line-height: 48px !important;
    }

    .amy-banner-heading{
        font-size: 48px !important;
        line-height: 48px !important;
    }
    .heading-one{
        font-size: 25px !important; 
        line-height: 32px !important;
    }

    .heading-two,.heading-three{
        font-size: 18px !important; 
        line-height: 25px !important;
    }
    .heading-four {
        font-size: 16px !important; 
        line-height: 22px !important;
    }
}

@media screen and (max-width: 960px) {
    .banner-heading{
        font-size: 35px !important; 
        line-height: 40px !important;
    }
    .connect-banner .banner-heading{
        font-size: 50px !important;
        line-height: 58px !important;
    }
    .amy-banner-heading{
        font-size: 38px !important;
    }
    .heading-one{
        font-size: 25px !important;
        line-height: 32px !important;
    }
    .nav-btn{
        font-size: 16px !important;
    }
    .footer-links, .card-value{
        font-size: 16px !important;
    }
    .contact-numbers-div li a , .contact-numbers-div li span{
        font-size: 16px !important;
    }
    .sec-padding{
        padding: 5rem 0 !important;
    }
    .sec-padding.pb-0 {
        padding-bottom: 0 !important;
    }
    .sec-padding.pt-0 {
        padding-top: 0 !important;
    }

    .banner-sec {
        padding: 60px 0 !important;
    }
    .agreement-padding{
        padding: 40px 0 !important;
    }

    .whats-new-label .paragraph-global-text{
        font-size: 14px !important;
    }

}