@import './variables';

// Common Button Styles
$button-padding: 10px 20px;
$button-font-size: 15px;
$button-border-radius: 8px;
$button-min-width: 100px;
$button-min-height: 45px;
$button-transition: transform .1s ease-out;
$button-box-shadow: 0 0 10px $box-shadow-color;

@mixin button-base {
  width: fit-content;
  min-width: $button-min-width;
  min-height: $button-min-height;
  padding: $button-padding;
  font-size: $button-font-size !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: $button-border-radius !important;
  outline: none;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  transition: $button-transition;
  letter-spacing: unset !important;
}

@mixin gradient-background($start-color, $end-color) {
  background: linear-gradient(to right, $start-color, $end-color);
}

@mixin button-hover($color, $hover-bg-color: $color) {
  &:hover, &:focus {
    background-color: $hover-bg-color !important;
    color: $color;
    opacity: 0.9;
    outline: 0 !important;
    box-shadow: $button-box-shadow;
  }
}

// Button Styles
.save-btn, .cancel-btn, .delete-btn, .secondary-save-btn, .showMore-btn, .showMore-btn-cancel, .black-btn, .sub-btn ,.see-more-btn{
  @include button-base;
}

.save-btn {
  color: $aumnics-white;
  border: 1px solid $new-primary-button-color;
  background-color: $new-primary-button-color;
  @include button-hover($aumnics-white, $new-primary-button-hover-color);
}

.cancel-btn {
  color: $primary-blue-color-2;
  border: 1px solid $primary-blue-color-2;
  border-color: $primary-blue-color-2;
  background-color: $aumnics-white;
  @include button-hover($primary-blue-color-2, #ebe6ff);
}

.delete-btn {
  color: $aumnics-white;
  border: 1px solid $delete-color;
  background-color: $delete-color;
  @include button-hover($aumnics-white);
}

.showMore-btn {
  color: $aumnics-white;
  border: 1px solid $new-primary-button-color;
  background-color: $new-primary-button-color;
  @include button-hover($aumnics-white, $new-primary-button-hover-color);

  &.white-btn {
    color: $primary-blue-color-2;
    border-color: $primary-blue-color-2;
    background-color: $aumnics-white;
    background: $aumnics-white;
    @include button-hover($primary-blue-color-2, #ebe6ff);
  }
}

.save-btn.cfa, .showMore-btn.cfa {
  color: $aumnics-black;
  border: 1px solid $cfa-btn-color;
  background-color: $cfa-btn-color;
  @include button-hover($aumnics-black, $cfa-btn-hover-color);
}

.showMore-btn-cancel {
  color: $primary-blue-color-2;
  border: 1px solid $primary-blue-color-2;
  background-color: transparent !important;
  @include button-hover($primary-blue-color-2, #ebe6ff);

  &.white-btn {
    background-color: transparent !important;
    border: 1px solid $aumnics-white;
    color: $aumnics-white;
  }
}

.secondary-save-btn {
  background-color: $secondary-save-btn-color;
  color: $primary-blue-color-2;
  border: 1px solid $secondary-save-btn-color;
  @include button-hover($primary-blue-color-2);
}


.secondary-save-btn:hover {
  background-color: $secondary-save-btn-color !important;
  color: $primary-blue-color-2 ;
}

.black-btn {
  color: $aumnics-white;
  border: 1px solid $primary-blue-color-2;
  background-color: $primary-blue-color-2;
  font-size: $button-font-size;
  @include button-hover($aumnics-white);
}

.sub-btn {
  color: $aumnics-white;
  border-color: $primary-blue-color-2;
  background-color: $primary-blue-color-2;
  @include button-hover($aumnics-white);
}

.see-more-btn {
  color: $primary-blue-color-2;
  font-weight: 500;
  background-color: #f1f1ff;
  border: none;
  @include button-hover($primary-blue-color-2,#e7e7ff);
}

.rounded {
  border-radius: 50px !important;
}


// Common Button Adjustments
.buttons-div {
  margin: 30px 0;

  button {
    min-height: 44px !important;
  }

  > .buttons-div:not(:last-child) > button,
  > button:not(:last-child):not(.dropdown-toggle) {
    margin-right: 15px;
  }
}

button:disabled {
  opacity: 0.8 !important;
  cursor: no-drop !important;
}

button.medium {
  font-weight: 400 !important;
  padding: 4px 12px !important;
  border-radius: $button-border-radius;
  min-width: 70px;
  font-size: 14px !important;
  line-height: 1rem;
  min-height: 32px;
}

.save-btn.btn-rounded,
.cancel-btn.btn-rounded,
.delete-btn.btn-rounded,
.secondary-save-btn.btn-rounded,
.showMore-btn.btn-rounded,
.showMore-btn-cancel.btn-rounded,
.black-btn.btn-rounded,
.sub-btn.btn-rounded,
.see-more-btn.btn-rounded {
  border-radius: 50px !important;
  font-size: 16px !important;
  padding: 12px 30px; /* Directly use padding instead of a variable */
}



// Modal Adjustments
.modal .buttons-div {
  margin: 10px 0;
}

.modal-content {
  border-radius: 4px !important;
}

.modal-footer {
  justify-content: flex-start;
}

// Spinner
.loader {
  color: #000000;
  text-indent: -9999em;
  overflow: hidden;
  display: flex;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 3px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* Default button layout for larger screens (Side by side) */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Adds spacing between buttons */
}

.button-container .btn {
  display: inline-block;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;  /* Stack buttons vertically */
    align-items: stretch;    /* Make buttons take full width */
  }

  .button-container .btn {
    width: 100%;              /* Make buttons full width */
  }
}
