 
.icon-color-code-1 { /* Find Answers */
	color: #007bff; /* Blue */
	background-color: #e6f7ff; /* Light Blue Background */
  }
  
  .icon-color-code-2 { /* Automate Tasks */
	color: #dc3545; /* Red */
	background-color: #fff5f5; /* Light Red Background */
  }
  
  .icon-color-code-3 { /* Hire Contract Talent */
	color: #ffc107; /* Yellow */
	background-color: #fff9e6; /* Light Yellow Background */
  }
  
  .icon-color-code-4 { /* Find Insights */
	color: #28a745; /* Green */
	background-color: #e8f9f1; /* Light Green Background */
  }
  
  .icon-color-code-5 { /* Plan Collaboratively */
	color: #9c27b0; /* Purple */
	background-color: #f9eafd; /* Light Purple Background */
  }
  
  .icon-color-code-6 { /* Set Goals and Targets */
	color: #e83e8c; /* Pink */
	background-color: #fff4f7; /* Light Pink Background */
  }
  
  .icon-color-code-7 { /* Sprint Planning */
	color: #fd7e14; /* Orange */
	background-color: #fff9ec; /* Light Orange Background */
  }
  
  .icon-color-code-8 { /* Time Sheets */
	color: #17a2b8; /* Cyan */
	background-color: #eafcfe; /* Light Cyan Background */
  }
  
  .icon-color-code-9 { /* Industry Templates */
	color: #0056b3; /* Dark Blue */
	background-color: #e6f0f9; /* Light Dark Blue Background */
  }
  
  .icon-color-code-10 { /* Compare Agencies */
	color: #20c997; /* Teal */
	background-color: #eafefa; /* Light Teal Background */
  }
  
  .icon-color-code-11 { /* Vertical Marketplace */
	color: #8b4513; /* Brown */
	background-color: #f9f2ea; /* Light Brown Background */
  }
  
  .icon-color-code-12 { /* One-to-One Chat */
	color: #228b22; /* Dark Green */
	background-color: #eaf9e9; /* Light Dark Green Background */
  }
  
  .icon-color-code-13 { /* Group Chat */
	color: #9c27b0; /* Purple */
	background-color: #f9eafd; /* Light Purple Background */
  }
  
  .icon-color-code-14 { /* Video Calls */
	color: #ff7f50; /* Coral */
	background-color: #fff9f2; /* Light Coral Background */
  }
  
  .icon-color-code-15 { /* File Sharing */
	color: #ff6347; /* Tomato */
	background-color: #fff7f4; /* Light Tomato Background */
  }
  
  .icon-color-code-16 { /* Gray (for any additional icons) */
	color: #6c757d; /* Gray */
	background-color: #f6f7f9; /* Light Gray Background */
  }
  
  