@import '../../assets/styles/variables';


//  dropdown css 

.dropdownSelectedOptionDiv {
    padding: 5px 40px 5px 15px;
    border-radius: 50px;
    border: 1px solid $new-border-color !important;
    background-image: url("./../icons/latest-icon/down-chevron.svg");
    background-repeat: no-repeat;
    background-position: right 15px center;
    font-size: 12px !important;
    min-height: 32px !important;
    background-size: 18px;
    cursor: pointer;
    &:hover{
        background-color: #f0f1f3;
    }
}

.dropdownSelectedOptionDiv.rectangular {
    border-radius: 4px !important;
    min-height: 39px !important;
    padding: 8px 40px 8px 15px;
}

.input-field-container {
    padding: 0px 15px;   
    min-height: 39px !important;
    display: flex;
    align-items: center;
}

.overlay-profile {
    margin-left: -10px;
}

.dropdownOptionsParentDiv {
    max-height: 300px !important;
    overflow-y: scroll;
    .dropdownOption {
        cursor: pointer;
        padding: 10px 15px;
        font-size: 13px;
        border-radius: 8px;
        color: $black;
        &:hover{
            background-color: #f0f1f3;
        }
    }

    .dropdownOption.selectedOption , .dropdownOption.selectedOption:hover{
        background-color: #E1E1E1;
    }
}

.dropdownOptionsPanel.scrollable .p-overlaypanel-content{
    padding: 10px 0 !important;
    .dropdownOptionsParentDiv {
        padding: 0px 10px !important;
    }
}

.prime-multiselect, .prime-select {
    .p-dropdown-header, .p-multiselect-header {
        padding: 0 !important;
        .p-dropdown-filter ,.p-multiselect-filter{
            border: none !important;
            border-bottom: 1px solid $border-color !important;
            border-radius: 0px !important;
            padding: 8px 8px 8px 40px !important;
        }
        .p-dropdown-filter-icon ,.p-multiselect-filter-icon{
            left: 15px;
            display: block !important;
        }
        .p-inputtext:enabled:focus {
            box-shadow: none !important;
        }
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        background-color: #E1E1E1 !important;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item , .p-multiselect-item{
        margin: 4px 10px !important;
        border-radius: 6px !important;
    }

    .p-dropdown-panel.p-component {
        margin: 10px 0 !important;
    }
}



//radio buttons
.radio-toolbar label.rounded-label {
    padding: 5px 14px !important;
    font-size: 13px !important;
    border-radius: 50px !important;
}


//icons css

.hoverEffect i{
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none !important;
  
    &:hover {
      background-color: #f3f3f3;
    }
   
  }

  .hoverEffect:hover {
    i {
        color: $black !important;
    }
  }

  .rotate-90.icon-gray {
    color: $black !important;
  }




  //Panel css

  .centeredPrimeModal.p-dialog {
    border-radius: 16px !important;
  }

  .centeredPrimeModal.p-dialog .p-dialog-content {
    padding: 1.5rem !important;
  }

  .centeredPrimeModal.p-dialog .p-dialog-header{
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
  }

  .centeredPrimeModal.p-dialog.borderless-style .p-dialog-header{
    border-bottom: none !important;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    padding-bottom: 0 !important;
  }

  .centeredPrimeModal.p-dialog .p-dialog-footer{
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    border-top: 1px solid $border-color !important;
  }

  .TaskActionModal.p-dialog {
    border-radius: 16px !important;
  }

  .TaskActionModal.p-dialog .p-dialog-content {
    padding: 1.5rem !important;
    padding-bottom: 0px !important;
  }

  .TaskActionModal.p-dialog .p-dialog-header{
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    border-bottom: none !important
  }

.TaskActionModal.p-dialog .p-dialog-content {
    padding: 1.5rem !important;
    padding-bottom: .2rem !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    border-top: 1px solid $border-color !important;
}

.TaskActionModal.right-sided-modal.p-dialog{
 margin: 0;
 min-height: 99vh;
 max-height: 99vh;
}

.TaskActionModal.right-sided-modal.p-dialog .p-dialog-header{
  border-top-right-radius: 4px !important;
}

.TaskActionModal.right-sided-modal.p-dialog .p-dialog-content {
  border-bottom-right-radius: 4px !important;
}

.recommendationTabs.p-dialog .p-dialog-content{
  padding: 0rem !important;
  overflow-x: hidden;
  border-radius: 4px !important;
}

.recommendationTabs.p-dialog {
  margin: 0px !important;
  margin-top: 2px !important;
}

.recommendationTabs{
  min-height: 99vh;
  max-height: 99vh;
}

.activityTab.p-dialog .p-dialog-content{
  overflow:  hidden !important;
  min-height: 90vh;
}

.globalSearchModal.p-dialog .p-dialog-content{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.TaskActionModal.p-dialog .p-dialog-content {
  padding-top: 0px !important;
}

  .userSelectionPanel .p-overlaypanel-content {
    padding: 0 !important;
    .users-list-parent {
        padding: 10px !important;
    }

    .profileLogo {
        position: relative;
        .remove-icon {
            color: $error-color;
            position: absolute;
            bottom: -5px;
            right: -5px;
        }
    }
  }


// prime chips
.prime-chip {
  border-radius: 5px 50px 50px 5px !important;
  .p-chip {
      border: none !important;
      color: $aumnics-white !important;
      background-color: transparent !important;      
  }
}

.linked-task-panel.p-overlaypanel {
  border-radius: 6px !important;
  background: transparent !important;
  background-color: transparent !important;
}

.task-list-panel{
  box-shadow: 0 8px 8px 0px rgba(0, 0, 0, 0.25) !important;
  background: $aumnics-white;
  border-radius: 6px;
}

.relationship-bar{
  .p-scrollpanel-bar{
    display: none;
  }
  .p-scrollpanel-content {
    padding: 0px !important;
  }
}


.panel-layout-container {
  display: flex;
  //height: 100vh;
}

.main-component-content {
  flex: 1;
  transition: margin-right 0.3s ease;
}

.side-panel {
  transition: transform 0.3s ease;
  background-color: $chat-menu-div;
}


.side-panel.pm-panel-collapsed {
  width: 0px;
  min-width: 0px;
  transition: transform 0.3s ease;
}
.side-panel.expanded{
  min-width: 260px;
  width: 260px;
  transition: transform 0.3s ease;
}

.pm-panel-collapsed {
  transform: translateX(100%);
}

.expanded {
  transform: translateX(0);
}

.toggle-button {
  // background-color: $aumnics-white;
  top: 12px;
  right: 18px;
  border-radius: 50px;
  color: $black;
  padding: 4px 10px;
  cursor: pointer;
  z-index: 1001;
  transition: right 0.3s ease;
}

.pm-panel-collapsed ~ .toggle-button {
  right: 0;
}

.fa-arrow-left, .fa-arrow-right {
  font-size: 16px;
}  



.commonProjectManagementHeader {
  padding: 7px 20px;
  position: sticky;
  top: 0;
  height: 60px;
  background-color: $aumnics-white;
  // z-index: 200;
  border-bottom: 1px solid $border-color;
}

.main-right-panel-sec {
  position: sticky;
  top: 0;
}

.new-checkbox {
  .p-checkbox .p-checkbox-box.p-highlight {
    width: 16px;
    height: 17px;
  }
  .pi-check:before {
    font-size: 10px !important;
    bottom: 2px;
    position: relative;
  }
}
.taskDetailesDialogueModal.p-dialog {
  border-radius: 16px !important;
}
.taskDetailesDialogueModal.p-dialog-content {
  overflow-y: hidden !important;
  flex-grow: 0 !important;
  padding: 0 !important;
}

.taskDetailesDialogueModal.p-dialog .p-dialog-header{
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  padding: 5px 12px;
}

.taskDetailesDialogueModal.p-dialog .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  // flex-grow: 0 !important;
  padding: 0 !important;
  overflow-y: hidden;
}

.p-dialog-without-footer.p-dialog .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.invoiceTab {

  .arrowDiv {
    display: none !important;
  }

  &:hover {
    .arrowDiv {
      display: flex !important;
      max-height: 24px;
      align-items: center;
      justify-content: center;
    }
    .profileLogoDiv {
      display: none !important;
    }
  }
}

.invoiceTab.emptyProjectsTab {
  &:hover {
    .arrowDiv {
      display: none !important;
    }
    .profileLogoDiv {
      display: block !important;
    }
  }
}
.invitePeopleModal.p-dialog .p-dialog-content {
  border-top: none !important;
}

.fromNewTaskManager.task-priority-panel {
  max-height: 500px;
  overflow-y: overlay;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
}

.fromNewTaskManager.task-priority-panel.p-overlaypanel {
  min-width: 180px;
}

.datatable-body-cell-label {
  font-size: 14px !important;
}

.conflictResolvingModal{
  min-height: 100vh !important;
  margin: 0 !important;
  border-radius: 8px !important;

  .p-dialog-header {
    border: none !important;
    border-top-left-radius: 12px !important;
  }
  .p-dialog-content {
    border-bottom-left-radius: 12px !important; 
    padding-bottom: 0px !important;
  }

  .p-dialog-header-close-icon{
    display: none;
  }
}

.closeProfileBtn {
  position: absolute;
  left: -41px;
  top: 10px;
  //font-size: 1.5rem !important;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  cursor: pointer;
  outline: none;
}

.closeProfileBtn .pi-times {
  transition: transform 0.3s ease; 
}

.closeProfileBtn:hover .pi-times {
  transform: rotate(90deg); 
}
.taskAddingDialgue.p-dialog {
width: 700px !important;}
.taskAddingDialgue.p-dialog .p-dialog-content {
  padding: 0px !important;
}
.taskAddingDialgue.p-dialog .p-dialog-header {
  padding: 14px !important;

}
.backLoggedTaskModal.p-dialog .p-dialog-content {
    padding: 10px 25px !important;
}
.backLoggedTaskModal.p-dialog .p-dialog-header{
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}
.backLoggedTaskModal.p-dialog .p-dialog-content{
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}
.backLoggedTaskModal.p-dialog{
  border-radius: 16px !important;
}
.newMilestoneModal .p-dialog-mask {
  z-index: 20000 !important;
}
.taskDetailesDialogueModal .p-dialog-mask  {
  z-index: 1001 !important;
}

.fromNewTaskManager.notification-panel.p-overlaypanel {
  left: 300px !important;
}

.fromNewTaskManager.newNotificationPanel {
  max-height: 90vh !important;
  overflow-y: hidden !important;
  left: auto !important;
  right: 80px !important;
}

@media (max-width: 676px) {
  .fromNewTaskManager.newNotificationPanel {
    right: 5px !important;
  }
}
.feedEmojiModal .p-dialog-header{
  padding: 12px 15px 12px 30px !important;

}
.fromNewTaskManager.user-list-panel {
  max-height: 90vh;
  .p-overlaypanel-content {
    padding: 0px !important;
  }
}
.fromNewTaskManager.newProfileHeaderOptionPanel {
  max-height: 90vh;
  overflow-y: overlay;
}
.layout-scroll {
  overflow-x: hidden;
}
.newProfileHeaderOptionPanel::-webkit-scrollbar, .layout-scroll::-webkit-scrollbar {
  width: 8px;  /* Make scrollbar thin */
  border-radius: 4px;
}
.layout-scroll::-webkit-scrollbar {
  width: 9px;  /* Make scrollbar thin */
  border-radius: 4px;
}
.newProfileHeaderOptionPanel::-webkit-scrollbar-track, .layout-scroll::-webkit-scrollbar-track{
  background-color: #f1f1f1; /* Track color */
}
.layout-scroll::-webkit-scrollbar-track {
  background-color: #fff;
}
.layout-scroll:hover::-webkit-scrollbar-track{
  background-color: #f1f1f1; /* Track color */
}

.newProfileHeaderOptionPanel::-webkit-scrollbar-thumb, .layout-scroll::-webkit-scrollbar-thumb{
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 5px;
}
.layout-scroll::-webkit-scrollbar-thumb{
  background-color: #fff; /* Scrollbar thumb color */
}
.newProfileHeaderOptionPanel::-webkit-scrollbar-thumb:hover, .layout-scroll:hover::-webkit-scrollbar-thumb, .example-viewport::-webkit-scrollbar-thumb:hover {
  background-color: rgb(173, 179, 189); /* Scrollbar thumb color */
}

.packageScroll:hover::-webkit-scrollbar-track, .packageScroll::-webkit-scrollbar-track, .packageScroll::-webkit-scrollbar-thumb, .packageScroll:hover::-webkit-scrollbar-thumb{
  background-color: transparent !important; /* Track color */
}

.newProfileHeaderOptionPanel::-webkit-scrollbar-button:single-button {
  background-color: transparent; /* Button color */
  height: 15px;  /* Button height */
  width: 10px;  /* Button width */
}

.newProfileHeaderOptionPanel::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23555" viewBox="0 0 24 24" width="16" height="16"%3E%3Cpath d="M7 14l5-5 5 5H7z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.newProfileHeaderOptionPanel::-webkit-scrollbar-button:single-button:vertical:increment {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23555" viewBox="0 0 24 24" width="16" height="16"%3E%3Cpath d="M7 10l5 5 5-5H7z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.calander-panel {
  left: auto !important;
  right: 63px !important;
}

.newUserFilterPanel {
  left: auto !important;
  right: 2px !important;
  height: calc(99vh - 125px);
  top: 115px !important;
  overflow-x: hidden;
}

//Table New Css

.amyTableHeader {
  font-size: 11px !important;
  //color: rgb(101, 111, 125) !important;
  text-transform: uppercase;
  opacity: .9;
  p, span, div {
    font-size: .75rem !important;
   // color: rgb(101, 111, 125) !important;
    text-transform: uppercase;
  }
  th span, th {
    font-size: 14px !important;
    font-weight: 500;
  }
  th {
    border: none;
    background-color: transparent !important;
    opacity: .9;
    font-weight: 400;
  }
  tr:hover {
    background-color: transparent !important;
  }
}

.amyTableBody {
  p, span, div {
    font-size: 14px !important;
  }
  td, th {
    border: none;
    background-color: transparent;
  }
}
.table-container {
  overflow-x: auto;
}

// icons styles in project management 

.pm-common-icon {
  font-size: 14px !important;
  color: $new-icon-default-color;
}

img.pm-common-icon, svg.pm-common-icon{
  width: 15px !important;
  height: 15px !important;
}

.newCreateEventModal{
  align-items: flex-start;
  padding: 1rem 1rem;
}
.newCreateEventModal .p-dialog-header{
  border-bottom: 1px solid $border-color !important;
  padding: 1rem 1rem;
  padding-bottom: 5px !important;
}

.newViewEventModal{
  .p-dialog-header {
    padding-bottom: 8px !important;
    border-bottom: 1px solid $border-color !important;
  }
  .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.scrollable-table {
  margin: auto;
  overflow-x: auto;
  
  table{
   
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
    white-space: nowrap;
    thead tr {
        th {
            vertical-align: middle;
        }
    }

    tbody {
        td {
            text-align: left;
            vertical-align: middle;
            font-size: 14px;
        }
    }
  
  tr>th:first-child,tr>td:first-child {
    background-color: $aumnics-white;
    position: sticky;
    left: 0;
  }
}
}

.fromNewTaskManager.menu-list-panel {
  max-height: calc(100vh - 48px);
  left: 2px !important;
  top: 34px !important;
  .p-overlaypanel-content {
    padding: 2px !important;
  }
}

.newProjectFilterPanel {
  left: auto !important;
  right: 3px !important;
  overflow-x: hidden;
}

.newProjectManagementFilterPanel {
  left: auto !important;
  right: 3px !important;
  overflow: hidden;
  top: 35px !important;
}
.taskColumsAction {
  max-height: calc(100vh - 96px) !important;
  top: 45px;
  left: auto;
  right: -10px;
}



// on hover effect 
.showOnHoverItem {
  display: none;
  cursor: pointer;
}
.hoverParent:hover {
  .showOnHoverItem {
    display: block;
  }
}

.f-0-8rem{
  font-size : 0.8rem;
}
.f-0-6rem{
  font-size : 0.6rem;
}

.border-less-accordian .p-accordion .p-accordion-header .p-accordion-header-link {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border: none !important;
  &:hover{
    background-color: $cardsHoverBg !important;
  }
}

.border-less-accordian .p-accordion .p-accordion-content {
  border-radius: 8px !important;
  border: 1px solid $border-color !important;
  border-top: 1px solid $border-color !important;
}

.subscription-accordian .p-accordion .p-accordion-header .p-accordion-header-link {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: #40576d12;
  &:hover{
    background-color: $cardsHoverBg !important;
  }
}

.subscription-accordian .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link ,
.subscription-accordian .p-accordion p-accordiontab:last-child .p-accordion-content{
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.subscription-accordian .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.subscription-accordian .p-accordion .p-accordion-header .p-accordion-header-link {
  .pi {
    font-size: 20px !important;
  }
}

.subscription-accordian .p-accordion .p-accordion-content {
  padding: 0;
}

.addLineUpTasks{
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid $placeholder-color;;
  border-style: dashed;
  color: $placeholder-color;;
  font-weight: 500;
  &:hover{
      border-color: $primary-blue-color-2 !important;
      color:  $primary-blue-color-2 !important;
  }
}

.feedModal.p-dialog .p-dialog-content {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem !important;
}
.feedModal.p-dialog .p-dialog-header{
  padding: 1rem 1.22rem 1rem 2rem !important;
}

.feedModal.p-dialog .p-dialog-footer{
  padding: .7rem 1.39rem .7rem 2rem !important;
}
  
.editBlogModal.p-dialog .p-dialog-header {
  padding: .7rem 1rem .7rem 1.5rem;
}

.scrollable-Modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.calendarRightMenuPanel{
  top: 85px !important;
  left: auto !important;
  right: 5px !important;
}
.users-list-panel{
  max-height: calc(100vh - 165px);
  min-height: 300px;
}
.groupUserListMembers {
  max-height: 90vh;
  .p-overlaypanel .p-overlaypanel-content {
    padding: 10px 0px !important;
  }
}

.deleteModalDailogue.p-dialog .p-dialog-content {
  padding: 1rem !important;
  padding-bottom: 0px !important;
  padding-bottom: .2rem !important;
  border-top: 1px solid $border-color !important;
}

.deleteModalDailogue.p-dialog .p-dialog-header{
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom: none !important;
  padding: 1rem 1.5rem !important; 
}

.deleteModalDailogue.p-dialog .p-dialog-footer {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.deleteModalDailogue.p-dialog {
  border-radius: 8px !important;
}
.signUpHeader.p-dialog .p-dialog-header {
  display: none !important;
}
.signUpHeader.p-dialog .p-dialog-content {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0px !important;
  overflow-x: hidden;
  background-color: #f0f4f9;
}

.signUpHeader {
  width: 24rem !important;
  box-shadow: 0 0.0625rem 0.2875rem rgba(0, 0, 0, 0.106), 0 0.0625rem 0.325rem -0.625rem rgba(0, 0, 0, 0.106) !important;
}

.skillsPanal {
  .p-overlaypanel-content {
    padding: 8px 1px 8px 1px !important;
  }
}

@media (min-width: 900px) {
  .signUpHeader{
    width: 49rem !important;
  }
}

.selectedTemplate .card {
  border: 2px solid $primary-blue-color-2 !important;
}

.p-body-0.p-dialog .p-dialog-content {
  padding: 0px !important;
}
.listPanel  {
  overflow: scroll !important;
  overflow-x: hidden !important;
  max-height: 300px !important;
}
.fromNewTaskManager.noMainScroll {
  max-height: 500px;
  overflow: hidden !important;
}
.searchResultModal.p-dialog .p-dialog-content {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.searchActionsModal {
  width: calc(100vw - 40px) !important;
}