@mixin d-base {
    display: flex;
    align-items: baseline;
}

@mixin d-center {
    display: flex;
    align-items: center;
}

@mixin d-start {
    display: flex;
    align-items: flex-start;
}

@mixin d-end {
    display: flex;
    align-items: flex-end;
}


// base
@mixin d-base-around {
    @include d-base;
    justify-content: space-around;
}

@mixin d-base-between {
    @include d-base;
    justify-content: space-between;
}

@mixin d-base-center {
    @include d-base;
    justify-content: center;
}

@mixin d-base-evenly {
    @include d-base;
    justify-content: space-evenly;
}

// center
@mixin d-items-around {
    @include d-center;
    justify-content: space-around;
}

@mixin d-items-between {
    @include d-center;
    justify-content: space-between;
}

@mixin d-items-center {
    @include d-center;
    justify-content: center;
}

@mixin d-items-evenly {
    @include d-center;
    justify-content: space-evenly;
}