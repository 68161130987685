.form-group {
    margin-bottom: 30px;
}

.form-group.p-float {
    margin-bottom: unset;
    margin-top: 30px;
}

// .p-inputtext,
.form-control {
    display: block;
    width: 100%;
    height: 48px;
    padding: 5px 16px;
    margin-right: 3px;
    font-size: 16px;
    color: $text-head-color  !important;
    background-color: #fff !important;//$form-control-bg-color  !important;
    border: unset;
    border-bottom: 1px solid;
    border-color: $border-color;
    border-radius: 0 !important;
    -webkit-transition: border-color ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    box-shadow: none !important;

    &:focus {
        box-shadow: none !important;
    }
}

.form-input.form-control, .p-inputnumber .p-inputtext {
    border: 1px solid $border-color !important;
    border-radius: 6px !important;
}

.form-input.p-dropdown, .p-autocomplete-input.p-inputtext{
    width: 100%;
    border: 1px solid $border-color !important;
}

.p-datepicker .p-highlight, .p-dropdown-panel .p-highlight {
    background-color: $primary-blue-color-2 !important;
}
 

.error {
    color: $aumnics-red  !important;
}

.p-float .error {
    margin-bottom: 0px !important;
}